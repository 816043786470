<template>
	<div class="ld-recurso">
		<ld-recurso
			ref="recurso"
			id="recurso"
			class="ld-details__page"
		/>
	</div>
</template>

<script>
import LDRecursos from '@/components/organisms/LDRecursos'

export default {
	components: {
		'ld-recurso': LDRecursos
	},
	props: {
		recurso: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: []
	}),
		
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.ld-recurso{
	overflow: hidden;
	background: white;
	margin-top: 63px;
}

@include grid-media($g-desktop-up) {
	.ld-recurso{
		margin-top: 95px;
	}
}
</style>
