<template>
	<div>
		<header class="ci-header">
			
			<div class="ci-header__container">
				<ci-brand class="ci-header__brand" /> 

				<ci-navigation
					class="ci-header__navigation"
					:inline="true"
				/>
				
				<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank" class="rl_mob"><button class="cta_mob">Cadastre-se</button></a>
				<div>
					<ci-hamburger class="ci-header__hamburger" @click="$emit('menu')" 
					/>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
import CIBrand from '@/components/atoms/CIBrand'
import CINavigation from '@/components/molecules/CINavigation'
import CIHamburger from '@/components/atoms/CIHamburger'
import CILang from '@/components/molecules/CILang'
import CIText from '@/components/atoms/CIText'
import $ from 'jquery'

export default {
	props: {
		navigation: {
			type: Array,
			default: undefined
		},
		lang: {
			type: String,
			default: 'pt'
		},
	},

	mounted() {
	

	},
	components: {
		'ci-brand': CIBrand,
		'ci-navigation': CINavigation,
		'ci-hamburger': CIHamburger,
		'ci-lang': CILang,
		'ci-text': CIText
	}
}
</script>

<style lang="scss">
.rl_mob{
    position: absolute;
    right: 75px;
    display: block;
}
.cta_mob{
	background: #fff;
    color: #1cbbc0;
    border-radius: 20px;
    border: none;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 15px;
    outline: none;
    -webkit-box-shadow: 0px 1px 7px -3px #7ab3b5;
    box-shadow: 0px 1px 7px -3px #7ab3b5;
    -webkit-transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

}
.orange{
	background: #fdc522!important;
	
	box-shadow: 0 3px 7px rgba(black, .03);
	.ci-text{
		color: #fff;
	}
}
.white{
	background: rgba(255, 255, 255, 0.96) !important;
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.03);
	box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.03);

				transition: .1s $easeInOutQuad;

	.ci-text{
		color: #868a88!important;
	}
	.ci-header__container{
		padding: 15px 0!important;
	}
}
.citrusmenu{
	background: #fff;
	box-shadow: 0 3px 7px rgba(black, .03);
	display: none;
	.ci-navigation-citrus__label{
		color: #989e9e!important;
	}
	img{
		width: 110px;
	}
}
.ci-header{
	position: relative;
	background: rgba(0,0,0,0);

	.ci-navigation__label{
		color: #fff;
	}

	&__container{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 12px 7px;
		@include outer-container;

		a{
			flex: auto!important;
		}
	}
	&__navigation{
		display: none;
	}
	&__brand{
		width: 100px;
     	margin-left: 12px;
	}
	&__lang{
		position: absolute;
		right: 64px;
		top: 50%;
		transform: translateY(-50%);
	}
	&__hamburger{
		position: absolute;
		bottom: 5px;
		right: 16px;
	}

	@include grid-media($g-desktop-up){
		.rl_mob{
			display: none;
		}
		.ci-header{
			&__container{
				padding: 27px 0;
			}
			&__brand{
				width: 195px;
				margin: 0 8px;
				flex-shrink: 0;
			}
			&__navigation{
				display: flex;
				justify-content: flex-end;
				margin: 0 8px;
				flex: 1;
			}
			&__hamburger{
				display: none;
			}
			&__lang{
				position: relative;
				top: 0;
				right: 0;
				margin-right: 24px;
				transform: initial;
			}
		}
	}
}
.onlyB{
	margin-top: -9px;
	margin-left: 4px;
}
@media(max-width: 1023px){
	.citrusmenu{
		display: none!important;
	}
}
@media(min-width: 1024px){
	.citrusmenu{
		display: block!important;
	}
}
</style>
