<template>
	<div class="ci-loading"
		:class="[
			`ci-loading--${state}`
		]"
	>
		<img class="loader" :src="`${publicPath}images/loader.png`">
	</div>
</template>

<script>
import CILeaf from '@/components/atoms/CILeaf'
import { TweenMax, Power2 } from 'gsap/TweenMax'

export default {
	components: {
		'ci-leaf': CILeaf
	},

	props: {
		theme: {
			type: String,
			default: 'primary' // primary | accent | warn
		},
		state: {
			type: String,
			default: 'idle' // idle | loading | done
		}
	},

	data: () => ({
		progress: 0,
		tweenedProgress: 0,
		duration: 5
	}),

	computed: {
		animatedProgress: function() {
			return this.tweenedProgress.toFixed(0);
		}
	},

	watch: {
		state(value) {
			switch(value) {
				
				case 'done':
					this.progress = 100
					this.duration = .5
					break
			}
		},

		progress(value) {
			TweenMax.to(this.$data, this.duration, {
				tweenedProgress: value,
				ease: Power2.easeOut
			})
		},
	},
}
</script>

<style lang="scss">
.ci-loading{
	position: relative;
	width: 100vw;
	height: 100vh;
	background: #fff;
	margin-left: -1px;
	margin-top: -1px;

		.loader{
			position: absolute;
			width: 50px;
			top: 50%;left: 50%;
			z-index: 999999;
			margin-bottom: 0 auto;
			transform: translate(50%, 50%!important);
		}
}
</style>
