import Vue from 'vue'

export default {
	namespaced: true,

	state: {
		pages: {
			'hero': {},	
			'satisfacao': {},
			'connect': {},
			'whatsapp': {},
			'pesquisa': {},
			'faq': {},
			'funcionalidades': {},
			'precos': {},
			'detalhes': {},
			'cadastro': {},
			'termos': {}
		}
	},

	getters: {
		getHero(state) {
			return state.pages['hero']
		},
		getSatisfacao(state) {
			return state.pages['satisfacao']
		},
		getConnect(state) {
			return state.pages['connect']
		},
		getWhatsapp(state) {
			return state.pages['whatsapp']
		},
		getPesquisa(state) {
			return state.pages['pesquisa']
		},
		getFaq(state) {
			return state.pages['faq']
		},
		getFuncionalidades(state) {
			return state.pages['funcionalidades']
		},		
		getPrecos(state) {
			return state.pages['precos']
		},
		getDetalhes(state) {
			return state.pages['detalhes']
		},
		getCadastro(state) {
			return state.pages['cadastro']
		},
		getTermos(state) {
			return state.pages['termos']
		},
	},

	mutations: {
		set(state, payload) {
			// Organize generic data
			let page = {
				...payload,
				...payload.acf,
				title: payload.title.rendered,
				content: payload.content.rendered,
				excerpt: payload.excerpt.rendered
			}

			// Organize pages data
			switch (page.slug) {

				case 'hero':
					page.thumbnails = page.thumbnails ? page.thumbnails.url : undefined
					break
				
				case 'satisfacao':
					page.thumbnail = page.thumbnail ? page.thumbnail.url : undefined
					break

				case 'faq':
					page.icone = page.icone ? page.icone.url : undefined
					break

				case 'funcionalidades':
					page.brand = page.brand ? page.brand.url : undefined
					break

				// case 'pesquisa':
				// 	page.slide_figura = page.slide_figura ? page.slide_figura.url : undefined
				// 	break
	
			}

			Vue.set(state.pages, page.slug, page)
		}
	},

	actions: {
		fetch({ state, commit }) {
			return new Promise((resolve, reject) => {
				Vue.http.get('pages')
					.then(response => {
						// Set pages
						response.body.forEach(page =>
							commit('set', page)
						)
						// Return pages
						return resolve(state.pages)
					})
			})
		}
	}
}
