<template>
	<div class="ld-home">
		<ld-hero
			ref="hero"
			id="hero"
			class="ld-home__hero"		
			:initialized="true"
		/>
		<ld-semsair
			ref="semsair"
			id="semsair"
			class="ld-home__ssemsair"
		/>
		<ld-consultasrapidas
			ref="consultasrapidas"
			id="consultasrapidas"
			class="ld-home__consultasrapidas"
		/>
		<ld-recurso
			ref="recurso"
			id="recurso"
			class="ld-home__recurso"
		/>
		<ld-beneficios 
			ref="beneficios"
			id="beneficios"
			class="ld-home__beneficios"
		/>
		<!-- <ld-sobre 
			ref="sobre"
			id="sobre"
			class="ld-home__sobre"
		/> -->
<!-- 
		<ld-connect 
			ref="connect"
			id="connect"
			class="ld-home__connect"
			:title="connect.title"
			:content="connect.content"
			:initialized="initialized.includes('connect')"
		/>

		<ld-whatsapp 
			ref="whatsapp"
			id="whatsapp"
			class="ld-home__whatsapp"
			:title="whatsapp.title"
			:content="whatsapp.content"
			:initialized="initialized.includes('whatsapp')"
		/>

		<ld-pesquisa 
			ref="pesquisa"
			id="pesquisa"
			class="ld-home__pesquisa"
			:slides="pesquisa.slides"
			:title="pesquisa.title"
			:content="pesquisa.content"
			:initialized="initialized.includes('pesquisa')"
		/>

		<ld-beneficios 
			ref="beneficios"
			id="beneficios"
			class="ld-home__beneficios"
			:initialized="initialized.includes('beneficios')"
		/> 

		<ld-sobre 
			ref="sobre"
			id="sobre"
			:title="sobre.title"
			:content="sobre.content"
			:items="sobre.items"
			class="ld-home__sobre"
			:initialized="initialized.includes('sobre')"
		/>

		<ld-citrus 
			ref="citrus"
			id="citrus"
			:content="citrus.content"
			class="ld-home__citrus"
			:initialized="initialized.includes('citrus')"
		/> -->
	</div>
</template>

<script>
import LDHero from '@/components/organisms/LDHero'
import LDSemsair from '@/components/organisms/LDSemsair'
import LDConsultasRapidas from '@/components/organisms/LDConsultasRapidas'
import LDRecurso from '@/components/organisms/LDRecurso'
import LDPesquisa from '@/components/organisms/LDPesquisa'
import LDBeneficios from '@/components/organisms/LDBeneficios'
import LDSobre from '@/components/organisms/LDSobre'
import LDCitrus from '@/components/organisms/LDCitrus'

export default {
	components: {
		'ld-hero': LDHero,
		'ld-semsair': LDSemsair,
		'ld-consultasrapidas': LDConsultasRapidas,
		'ld-recurso': LDRecurso,
		'ld-pesquisa': LDPesquisa,
		'ld-beneficios': LDBeneficios,
		'ld-sobre': LDSobre,
		'ld-citrus': LDCitrus
		},
	metaInfo: {
      title: 'WebDoctor'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		semsair: {
			type: Object,
			default: () => ({})
		},
		consultasrapidas: {
			type: Object,
			default: () => ({})
		},
		recurso: {
			type: Object,
			default: () => ({})
		},
		pesquisa: {
			type: Object,
			default: () => ({})
		},
		beneficios: {
			type: Object,
			default: () => ({})
		},
		sobre: {
			type: Object,
			default: () => ({})
		},
		citrus: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: []
	}),
	watch: {
		scroll(value) {
			for (let ref in this.$refs) {
				const component = this.$refs[ref],
					offsetTop = component.$el.offsetTop - window.innerHeight

				if (value > offsetTop && !this.initialized.includes(ref))
					this.initialized.push(ref)
			}
		},

		active(value) {
			this.$emit('change', value)
		}
	},

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.ld-home{
	overflow: hidden;
	background: white;
	padding-top: 0;
}

@include grid-media($g-desktop-up) {
/*	.ld-home{
		padding-top: 70px;
	}*/
}
</style>
