<template>
	<div class="ld-citrus">	
		<ci-circle class="ld-citrus__circle" theme="blur"></ci-circle>
		<img class="ld-citrus__plot ld-hero__animate" :src="`${publicPath}images/shapelemon.png`">
		<div class="ld-citrus__container">
			<div class="ld-citrus__content">
				<img class="ld-citrus__brand ld-hero__animate" :src="`${publicPath}images/citrusbrand.png`">
				<ci-text class="ld-citrus__text">O LemonDesk faz parte do <strong>Ecossistema Citrus de Experiência do Cliente</strong>. Conheça mais sobre o Citrus e como ele vem transformando limões de negócios em deliciosas limonadas.</ci-text>
				<a href="http://citrus.cx" target="_blank"><button class="ld_cta_pattern">CONHEÇA O CITRUS</button></a>
			</div>
		</div>
		<p class="warningTrial">*Teste por 14 dias grátis e prorrogue por mais 7 dias sem custos</p>
	</div>
</template>

<script>
import CICircle from '@/components/atoms/CICircle'
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-circle': CICircle
	},
	props: {
		
		title: {
			type: String,
			default: 'Converse com seu cliente por onde ele preferir '
		},
		content: {
			type: String,
			default: 'Através de uma completa suíte de comunicação, o LemonDesk permite o atendimento aos clientes, por meio de qualquer canal de comunicação, em uma única ferramenta. '
		},
		
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">

.warningTrial{
	position: absolute;
	bottom: 0;
	z-index: 88;
	font-size: 14px;
	color: #3a3937;
	text-align: center;
	width: 100%;
	margin: 7px 0;
}
.ld-citrus{
	position: relative;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 30;
	padding: 7vh 0;
	background: #fcc533;
	overflow: hidden;
		
	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: center;
	}
	
	&__content{
		width: calc(100% - 32px);
		float: left;
		margin-left: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		flex-flow: column wrap;
		
	}
	&__text{
		color: #272727;
	    line-height: 150%;
	    float: none;
	    max-width: 475px;
	    text-align: center;
	    margin: 37px 0!important;
	    margin-left: 0!important;
	}
	&__circle {
	    position: absolute;
	    right: -72%;
	    bottom: 54%;
	    width: 360px;
	    height: auto;
	    z-index: -1;
	}
	&__plot {
	    position: absolute;
	    width: 100%;
	    left: -15%;
	    top: -1%;
	    z-index: -1;
	    pointer-events: none;
	    -webkit-transform: rotate(3deg);
	    transform: rotate(3deg);
	}
	&__brand{
		pointer-events: none;
	}
	.mb-0{
		margin-bottom: 0;
	}
	@include grid-media($g-tablet-up) {
		&__plot{
		    position: absolute;
		    width: 100%;
		    left: -30%;
		    top: -1%;
		    z-index: -1;
		    pointer-events: none;
		    -webkit-transform: rotate(3deg);
		    transform: rotate(3deg);
		}
		&__circle {
		    position: absolute;
		    right: -26%;
		    bottom: 60%;
		    width: 365px;
		    height: auto;
		    z-index: -1;
		}
	}
	@include grid-media($g-desktop-up) {
		&__content{
			flex-flow: row wrap;
		}
		&__plot{
			position: absolute;
			width: 37%;
			left: -86px;
			top: -28px;
			z-index: -1;
			pointer-events: none;
			transform: rotate(3deg);
		}
		&__text{
			text-align: left;
		}
		&__circle {
		    position: absolute;
		    right: -14%;
		    bottom: 35%;
		    width: 433px;
		    height: auto;
		    z-index: -1;
		}
	}
}
@media(max-width: 600px){
	.ld-citrus{
		padding: 7vh 0 14vh;
	}
}
</style>
