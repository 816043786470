<template>
	<div class="ld-naoencontrado">
		<img class="ld-hero__shape ldne" :src="`${publicPath}images/shape-hero-01.png`">
		<div class="ld-naoencontrado__container">
			<div class="page-01-naoencontrado">
				<div class="sobre-page-text col-md-7 col-lg-6 mx-auto">
					<img :src="`${publicPath}images/plano404.png`" class="mb-3">
					<ci-text class="titlePage pr-0 txtaligncenter mt-5">Olá! Infelizmente sua <br> operadora de saúde ainda não está cadastrada ao Webdoctor.</ci-text>
					<ci-text class="contpage">Você pode preencher o formulário de contato para ser informado assim que o atendimento através do seu convênio estiver disponível. </ci-text>
				</div>
			</div>


			<div class="page-form-naoencontrado">
				<div class="container">
					<div class="row">
						<div class="col-md-7 col-lg-6 mx-auto">
							<div id="msgthanku" class="banner-cs-subtitle titleone mb-3 titles justify-content-center">
                                <!-- 🙂 -->
                                Formulário enviado!
								Entraremos em contato em breve. <br>
								Para mais informações, acesse: <br><router-link to="/">http://webdoctor.com.br/</router-link>
                            </div>
							<form @submit.prevent id="form_plano_nao_incluso" name="form_plano_nao_incluso">
			                    <div class="left mx-auto">
			                        <div class="fieldset" :class="{ 'hasError': $v.form.name.$error }">
			                            <label>NOME:</label>
			                            <input type="text" name="name"  v-model="form.name">
			                        </div>
			                        <div class="fieldset" :class="{ 'hasError': $v.form.email.$error }">
			                            <label>E-MAIL:</label>
			                            <input type="email" name="email" v-model="form.email">
			                        </div>
			                        <div class="fieldset" :class="{ 'hasError': $v.form.phone.$error }">
			                            <label>TELEFONE:</label>
			                            <input type="tel" name="phone" v-model="form.phone" v-mask="['(##) ####-####', '(##) #####-####']">
			                        </div>
			                        <div class="fieldset" :class="{ 'hasError': $v.form.operadora.$error }">
			                            <label>OPERADORA/PLANO DE SAÚDE:</label>
			                            <input type="text" name="cf_operadora" v-model="form.operadora">
			                        </div>
			                         <div class="summary text-red theerror" v-if="$v.form.$error">
	                                    Ops, para continuar preencha os campos destacados!
	                                </div>
			                    </div>
			                    <div class="col-md-12">
			                        <button type="submit" class="form_submit" @click="submit_PF">Me avise</button>
			                    </div>                         
							</form>
						</div>
					</div>
				</div>
			</div>



			<section class="page-02-naoencontrado mt-5 mb-3">
		       
		        <div class="container">
		            <div class="row">
		            	<div class="col-lg-11 col-md-12 box mx-auto">
			                <img :src="`${publicPath}images/planobg.png`" class="mb-3 planobg">
			                <div class="col-lg-10 col-md-12 mx-auto col-sm-12 d-flex boxcontent">
			                    <div class="banner-cs">
			                        <ci-text class="titlePage mb-4">Aguarde</ci-text>
			                        <p class="banner-cs-body" style="max-width: 530px;max-width: 417px;font-size: 19px;line-height: 163%;">
			                           Em breve, você também poderá agendar aqui as consultas particulares e ser atendido por médicos de todo o Brasil.
			                        </p>
			                    </div>
			                
			                    <div class="d-flex flex-column nocth" >

			                        <ci-text>Para saber mais sobre os nossos recursos e benefícios. Clique no botão abaixo:</ci-text>

			                        <router-link to="/recursos"><button class="form_submit">Saiba mais</button></router-link>
			                    </div>
			                </div>
		                </div>
		            </div>
		        </div>
		    
		    </section>

		    <img class="bottomshape" :src="`${publicPath}images/base-04.png`">
		</div>		
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'
import $ from 'jquery'
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
	components: {
		'ci-text': CIText,
	},
	metaInfo: {
		title: 'Meu plano não está no Webdoctor'
    },
	props: {
		title: {
			type: String,
			default: () => ""
		},
		content: {
			type: String,
			default: () => ""
		},
	},

	data: () => ({
		animated: false,
		visible: false,
		form: {
            name: "",
            email:"",
            phone: "",
            operadora: ""
        },
	}),
	validations: {
        form: {
            name: { required },
            email: { required, email },
            phone: { required },
            operadora: { required }
        },
    },
     methods: {
        submit_PF() {         
          this.$v.form.$touch();
          if(this.$v.form.$error) 

        return
            
            $("#form_plano_nao_incluso").hide();
            $("#msgthanku").fadeIn('fast');
            
        },
    }
}
</script>

<style lang="scss">
	.boxcontent{
		justify-content: space-between;
		position: relative;

		
	}
	.banner-cs{
		font-family: 'Poppins';
	}
	.banner-cs-body{
		line-height: 160%;
		font-size: 14.5px;
		font-weight: 500;
	}
	
	.brandscitrus{
		z-index: 9;
	}
	
.titlePage{
	color: #606666;
	margin-bottom: 22px;
	font-weight: bold;
	line-height: 135%; 
	margin-top: 0px;
	font-size: 23px;padding-right: 0!important;
}
.txtaligncenter{
	text-align: center;
}
.ld-naoencontrado{
	position: relative;
	display: flex;
	align-items: top;
	position: relative;
	z-index: 30;
    flex-flow: column;
	min-height: 90vh;
	color: #666666;
	font-family: 'Avenir';

	.content{
		padding: 0 16px;

		.obs{
			background: #efefef;
			border: solid 1px #dbdcdc;
			padding: 8px;
			border-radius: 5px;
		}
	}
	.lead{
	    margin: 0 auto 33px;
	    max-width: 290px;
	    font-size: 18px !important;
	    text-align: center!important;
	    color: #666666;
	    line-height: 137%;


		strong{
			font-weight: bold;
		}
	}
	
	&__page{	
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		-o-object-position: center center;
		object-position: center center;
		-o-object-fit: cover;
		object-fit: cover;
		right: 0;
		margin: auto;
		z-index: -1;
	}	
	
	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: top;
    	justify-content: center;
    	flex-flow: column;
	    margin-bottom: 15vh;
    	margin-top: 7vh;
	}
	
	.sobre-page-sobre{
		display: flex;
	}
	.page-01-naoencontrado{
		img{
			max-width: 415px;
			margin: 0 auto;
			float: none;
			display: block;
			width: 100%;
		}
	}
	.page-02-naoencontrado{
		.box{
			border-radius: 8px;
			background: #ffffff;
			padding: 75px 0;
			overflow: hidden;
			-webkit-box-shadow: 0px 0px 10px #f8f5eb;
			box-shadow: 0px 0px 20px #f2f2f2;
			position: relative;

			.planobg{
				position: absolute;
			    top: 0;
			    left: 0;
			    height: 100%;
			    width: 66%;
			}
			.form_submit{
				display: block;
				margin: 20px 0 0 0;
				background: #1cbbc0;
				text-align: center;
				font-size: 15px;
				border-radius: 40px;
				font-family: 'Poppins';
				padding: 12px 0px;
				width: 172px;
				box-shadow: 0px 3px 13px 0px #dedede;
				float: none;
				cursor: pointer;
				color: #fff;
				border: solid 1px #1cbbc0;
				font-weight: 500;
		    }
		    .nocth{
		    	width: 29%;
		    	justify-content: center;

		    	a{
		    		text-decoration: none;
		    	}
		    	span{
		    		font-weight: 500;
		    		color: #65666d;
		    	}
		    }
		}
	}
	.page-03-naoencontrado{
		@include grid-column(6);
		margin: 0 auto;

		p{
			font-size: 14.5px!important;
			line-height: 185%;
			color: #666666;
			font-weight: 400;
		}
		.topic{
			width: 16px;
			height: 15px;
			margin-right: 10px;
		}
		li{
			margin: 6px 0;
		}
		.boxer{
			background: #fff;
			box-shadow: 0px 0px 10px #f8f5eb;
			border-radius: 10px;
			padding: 40px 0 35px 0;
		}
		.titlePage{
			font-size: 28px;
			padding-right: 0!important;

		}
	}	
	@include grid-media($g-tablet-up){
		position: relative;
		display: flex;
		flex-flow: column wrap;
		align-items: top;
		position: relative;

		&__container{
			flex-flow: column;
		}
		.page-01-naoencontrado{
			display: flex;
			flex-flow: row;
			align-items: center;
		}
		.shape{
			position: absolute;
			right: 30%;
			height: 128%;
			bottom: 0;
			top: -40px;
		}
		.titlePage{
			font-size: 28px;
			padding-right: 0;
		}
		.brandscitrus{
			margin-right: 30px;
		}
	}
	@include grid-media($g-desktop-up){
		.titlePage{
			font-size: 28px!important;
			padding-right: 0!important;

		}
		&__container{
			flex-flow: column;

			.page-01-naoencontrado{
				@include grid-column(11);
				margin: 5vh auto 2vh;		
			}

			.page-03-naoencontrado{
				margin: 0 auto;

				p{
					font-size: 16px;
					line-height: 185%;
				}
				.topic{
					width: 16px;
					height: 15px;
					margin-right: 10px;
				}
				li{
					margin: 4px 0;
				}
			}	
		}
		.ldne{
			width: 52%;
			right: -22px;
			left: auto;
			top: -105px;
		}
		
		.contpage{
			color: #606666;
			font-size: 14.5px;
			line-height: 27px;
			text-align: center;
		}
		.boxer{
			background: #fff;
			box-shadow: 0px 0px 10px #f8f5eb;
			border-radius: 10px;
			padding: 30px 0 40px 0;
			position: relative;
		}
		.shape03{
			position: absolute;
			left: 0;
			height: auto;
			bottom: 0;
			width: 38%;
			
		}
	}
	#msgthanku{
		box-shadow: 0px 0px 10px #f2f2f2;
		padding: 61px;
		text-align: center;
		font-size: 19px;
		font-family: 'Poppins';
		display: none;
		margin-top: 33px;

		a{
			text-decoration: none;
			color: #1cbbc0;
			font-weight: 600;
		}
	}
	form{
		max-width: 93%;
		margin: 0 auto;

	    .fieldset{
	        label{
	            width: 100%;
	            color: #6e6868;
	            font-size: 14px;
	            margin: 0;
	            font-family: 'Poppins';
	            padding: 12px 0 4px 15px;
	            font-weight: 500;
	        }
	        input {
	            width: 100%;
	            border-radius: 30px;
	            padding: 8px 15px;
	            border: solid 1.50px #ebebeb;
	            outline: none;
	            font-size: 14px;
	            font-family: 'Poppins';
	            &:focus{
	                border-color: #1cbbc0;
	                background: #eaf6f6;
	            }
	        }
	        select {
	            width: 100%;
	            border-radius: 30px;
	            padding: 1px 15px;
	            border: solid 1.50px #ebebeb;
	            outline: none;
	            font-size: 14px;
	            font-family: 'Poppins';

	            &:focus{
	                border-color: #1cbbc0;
	            }
	        }
	    }
	    .form_submit{
	        display: block;
	        margin: 37px auto 0;
	        background: #1cbbc0;
	        text-align: center;
	        font-size: 15px;
	        border-radius: 40px;
	        font-family: 'Poppins';
	        padding: 15px 0px;
	        width: 180px;
	        float: none;
	        cursor: pointer;
	        color: #fff;
	        border: solid 1px #1cbbc0;
	    }
	}

	.hasError{
	    label{
	        color: #fc5d5d!important;
	    }
	    input{
	        border-color: #fc5d5d!important;
	    }
	    select{
	        border-color: #fc5d5d!important;
	    }
	}
	.theerror{
	    width: 100%;
	    text-align: center;
	    padding: 8px;
	    margin: 22px 0 0 0;
	    background: #fc5d5d;
	    color: #fff;
	    text-shadow: 1px 1px 2px #820a0a;
	    border-radius: 27px;
	    font-family: 'Poppins';
	    font-size: 14px;
	}
}
@media(max-width: 1200px){
	.collumns{
		width: 100%!important;
	}
	.page-02-naoencontrado .container{
		width: 100% !important;
		margin: 0;
		max-width: none;
	}
	.page-03-naoencontrado .container{
		width: 100% !important;
		margin: 0;
		max-width: none;
	}
		.shape03{
			display: none;
		}
		.shape{
		display: none;
	}

	.page-02-naoencontrado .row{
		margin: 0!important;

		.boxcontent .banner-cs{
			width: 73%;
		}
	}
	.brandscitrus{
		margin-right: 0!important;
	}
	.page-03-naoencontrado .inner .row{
		margin: 0!important;
	}
}

@media(max-width: 600px) {
	.page-02-naoencontrado{
		.box{
			padding: 40px 0 35px 0!important;
		}

	}
	 #msgthanku {
	    padding: 40px 11px!important;
	   }
	
	.boxcontent{
		flex-flow: column;
		padding-bottom: 35px;

		.brandscitrus{
			margin-top: 90px;
			a{
				width: 130px;

				img{

					margin-bottom: 24px!important;
				}
			}
		}
		
		.banner-cs{
			width: 100%!important;
			max-width: none!important;
		}
		.banner-cs-body{
			p{
				width: 100%;
				max-width: none!important;
			}
		}
	}
	.banner-cs-body{
		line-height: 178%;
		font-size: 16px;
		font-weight: 500;
	}
	.ld-naoencontrado .page-03-naoencontrado p{
		line-height: 178%;
		font-size: 16px!important;
		font-weight: 400;
	}
	.page-03-naoencontrado .banner-cs{
		    margin-bottom: 20px;
	}
	.nocth{
		width: 100%!important;
	}
	.planobg{
		display: none;
	}
}

</style>
