import Vue from 'vue'
import Router from 'vue-router'
import LDHome from '@/components/pages/LDHome'
import LDRecursos from '@/components/pages/LDRecursos'
import LDSobrePage from '@/components/pages/LDSobrePage'
import LDNaoEncontrado from '@/components/pages/LDNaoEncontrado'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
		{
			path: '/',
			name: 'home',
			component: LDHome
		},
		{
			path: '/recursos',
			name: 'recursos',
			component: LDRecursos
		},
		{
			path: '/sobre',
			name: 'sobre',
			component: LDSobrePage
		},
		{
			path: '/plano-nao-encontrado',
			name: 'naoencontrado',
			component: LDNaoEncontrado
		}
		
		
	],
	scrollBehavior(to, from, savedPosition) {
		const container = document.querySelector('.ci-app__body')

		container.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
})

export default router
