<template>
	<ld-home
		id="home"
		:hero="hero"
		:satisfacao="satisfacao"
		:connect="connect"
		:whatsapp="whatsapp"
		:pesquisa="pesquisa"
	/>
</template>

<script>
import LDHome from '@/components/templates/LDHome'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ld-home': LDHome
	},

	props: {

		
	},
	data: () => ({
		selected: '/funcionalidades',
		value: '/funcionalidades'
		}),
	computed: {
		...mapGetters({
			hero: 'page/getHero',
			satisfacao: 'page/getSatisfacao',
			connect: 'page/getConnect',
			whatsapp: 'page/getWhatsapp',
			pesquisa: 'page/getPesquisa',
		})
	},

	methods: {
	}
}
</script>
