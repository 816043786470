<template>
	<ld-recursos
		id="recursos"
	/>
</template>

<script>
import LDRecursos from '@/components/templates/LDRecursos'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ld-recursos': LDRecursos
	},

	props: {

		
	},
	data: () => ({
		selected: '/recursos',
		value: '/recursos'
		}),
	computed: {
		...mapGetters({
		})
	},

	methods: {
	}
}
</script>
