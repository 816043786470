<template>
	<div class="ld-sobrepage">
		<img class="ld-hero__shape" :src="`${publicPath}images/shape-hero-01.png`">
		<div class="ld-sobrepage__container">
			<div class="page-01-sobrepage">
				<div class="sobre-page-text col-md-6">
					<ci-text class="titlePage">Atendimento médico personalizado, mesmo em lugares diferentes.</ci-text>
					<ci-text class="contpage">O Webdoctor é a ferramenta de telemedicina e vídeo consultas que facilita integração de profissionais de saúde e pacientes de forma prática e eficiente, sem precisar sair de casa. Com recursos que garantem a segurança, sigilo e proteção dos dados, atende todas as exigências da Portaria nº 467 do Ministério da Saúde, de 20 de março de 2020, sobre o enfrentamento de emergência ao COVID-19. </ci-text>
				</div>
				<img class="col-md-6" :src="`${publicPath}images/webdoctorsobre.png`">
			</div>
			<section class="page-02-sobrepage mt-5 mb-3">
		       
		        <div class="container">
		            <div class="row">
		            	<div class="col-lg-11 col-md-12 box mx-auto">
			                <div class="col-lg-11 col-md-12 mx-auto col-sm-12 d-flex boxcontent">
			                    <div class="banner-cs">
			                        <ci-text class="titlePage mb-4">A empresa</ci-text>
			                        <p class="banner-cs-body" style="max-width: 530px;">
			                            A solução com tecnologia 100% nacional que faz parte do ecossistema de experiência do cliente: o Citrus, uma plataforma que unifica os canais de comunicação e registra os pontos de contato da jornada de atendimento e vendas dos clientes
			                            <br><br>
										A Citrus é uma empresa do Grupo Digivox, referência no país em soluções de comunicação unificada para clientes públicos e privados há mais de 14 anos. São mais de 200 mil usuários ativos e 70 milhões de chamadas por mês realizadas e monitoradas.
			                        </p>
			                    </div>
			                <img class="shape" :src="`${publicPath}images/page02sh.png`">
			                    <div class="d-flex flex-column justify-content-center align-items-center brandscitrus" >

			                        <a href="http://citrus.cx" target="_blank" style="width: 155px;"><img style="width: 100%;" src="https://citrus.cx/wp-content/uploads/2020/03/logo-citrus.png" class="img mb-5" alt="Citrus Squad"></a>
			                        <a href="http://digivox.com.br" target="_blank" style="width: 155px;"><img style="width: 100%;" src="https://citrus.cx/wp-content/uploads/2020/03/logo-digivox.png" class="img" alt="Digivox"></a>
			                    </div>
			                </div>
		                </div>
		            </div>
		        </div>
		    </section>
		    <section class="page-03-sobrepage my-1">
		        <div class="container">
		            <div class="row">
		            	<div class="col-lg-11 col-md-12 boxer mx-auto">
		            		<img class="shape03" :src="`${publicPath}images/Bg_03.png`">
			                <div class="col-lg-11 col-md-12 mx-auto inner">
			                	<div class="row">
			                		<div class="d-flex collumns" style="width: 31%;margin-right: 4%">
			                    <div class="banner-cs">
			                        <ci-text class="titlePage mb-4">Missão</ci-text>
			                        <p class="banner-cs-body">
			                            Entregar soluções inovadoras que melhorem a experiência do cliente por meio de uma equipe comprometida e parcerias estratégicas.
			                        </p>
			                    </div>
			                </div>
			                <div class="d-flex collumns" style="width: 33%;margin-right: 6%">
			                    <div class="banner-cs">
			                        <ci-text class="titlePage mb-4">Visão</ci-text>
			                        <p class="banner-cs-body">
			                            Ser referência por nossas soluções inovadoras de alto valor agregado, atuando globalmente como núcleo de sistema de comunicação, sendo líder em vendas no mercado empresarial nacional, através de alianças estratégicas e profissionais altamente capacitados.
			                        </p>
			                    </div>
			                </div>
			                <div class="d-flex collumns" style="width: 25%;margin-right: 0">
			                    <div class="banner-cs">
			                        <ci-text class="titlePage mb-4">Valores</ci-text>
			                        <p class="banner-cs-body">
			                            <ul class="banner-cs-body-ul" style="list-style: none;padding-left: 0;">
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Espírito de equipe</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Comprometimento</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Foco no cliente</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Iniciativa e proatividade</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Aprendizagem Contínua</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Ética, respeito e confiança</li>
			                                <li><img class="topic" :src="`${publicPath}images/topics.png`"> Criatividade e Inovação</li>
			                            </ul>
			                        </p>
			                    </div>
			                </div>
			                	</div>
			                </div>
			            </div>
		            </div>
		        </div>
		    </section>
		</div>		
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
	},
	metaInfo: {
		title: 'Sobre o Webdoctor'
    },
	props: {
		title: {
			type: String,
			default: () => ""
		},
		content: {
			type: String,
			default: () => ""
		},
	},

	data: () => ({
		animated: false,
		visible: false,
	}),
}
</script>

<style lang="scss">
	.boxcontent{
		justify-content: space-between;
	}
	.banner-cs{
		font-family: 'Poppins';
	}
	.banner-cs-body{
		line-height: 160%;
		font-size: 14.5px;
		font-weight: 500;
	}
	
	.brandscitrus{
		z-index: 9;
	}
	
.titlePage{
	color: #606666;
	margin-bottom: 22px;
	font-weight: bold;
	line-height: 135%;    padding-right: 20px;
	margin-top: 0px;
	font-size: 28px;
}
.ld-sobrepage{
	position: relative;
	display: flex;
	align-items: top;
	position: relative;
	z-index: 30;
    flex-flow: column;
	min-height: 90vh;
	color: #666666;
	font-family: 'Avenir';

	.content{
		padding: 0 16px;

		.obs{
			background: #efefef;
			border: solid 1px #dbdcdc;
			padding: 8px;
			border-radius: 5px;
		}
	}
	.lead{
	    margin: 0 auto 33px;
	    max-width: 290px;
	    font-size: 18px !important;
	    text-align: center!important;
	    color: #666666;
	    line-height: 137%;


		strong{
			font-weight: bold;
		}
	}
	
	&__page{	
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		-o-object-position: center center;
		object-position: center center;
		-o-object-fit: cover;
		object-fit: cover;
		right: 0;
		margin: auto;
		z-index: -1;
	}	
	
	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: top;
    	justify-content: center;
    	flex-flow: column;
	    margin-bottom: 15vh;
    	margin-top: 7vh;
	}
	
	.sobre-page-sobre{
		display: flex;
	}
	.page-02-sobrepage{
		.box{
			border-radius: 8px;
			background: #ffffff;
			padding: 40px 0;
			overflow: hidden;
			-webkit-box-shadow: 0px 0px 10px #f8f5eb;
			box-shadow: 0px 0px 11px #f2f2f2;
		}
		.titlePage{
			color: #1cbbc0!important;
			font-size: 32px!important;
		}
	}
	.page-03-sobrepage{
		@include grid-column(6);
		margin: 0 auto;

		p{
			font-size: 14.5px!important;
			line-height: 185%;
			color: #666666;
			font-weight: 400;
		}
		.topic{
			width: 16px;
			height: 15px;
			margin-right: 10px;
		}
		li{
			margin: 6px 0;
		}
		.boxer{
			background: #fff;
			box-shadow: 0px 0px 10px #f8f5eb;
			border-radius: 10px;
			padding: 40px 0 35px 0;
		}
		.titlePage{
			font-size: 32px!important;

		}
	}	
	@include grid-media($g-tablet-up){
		position: relative;
		display: flex;
		flex-flow: column wrap;
		align-items: top;
		position: relative;

		&__container{
			flex-flow: column;
		}
		.page-01-sobrepage{
			display: flex;
			flex-flow: row;
			align-items: center;
		}
		.shape{
			position: absolute;
			right: 30%;
			height: 128%;
			bottom: 0;
			top: -40px;
		}

		.brandscitrus{
			margin-right: 30px;
		}
	}
	@include grid-media($g-desktop-up){

		&__container{
			flex-flow: column;

			.page-01-sobrepage{
				@include grid-column(11);
				margin: 5vh auto;

				
			}	
			.page-03-sobrepage{
				margin: 0 auto;

				p{
					font-size: 16px;
					line-height: 185%;
				}
				.topic{
					width: 16px;
					height: 15px;
					margin-right: 10px;
				}
				li{
					margin: 4px 0;
				}
			}	
		}
		.titlePage{
			font-size: 28px!important;

		}
		.contpage{
			color: #606666;
			font-size: 14.5px;
			line-height: 27px;
		}
		.boxer{
			background: #fff;
			box-shadow: 0px 0px 10px #f8f5eb;
			border-radius: 10px;
			padding: 30px 0 40px 0;
			position: relative;
		}
		.shape03{
			position: absolute;
			left: 0;
			height: auto;
			bottom: 0;
			width: 38%;
			
		}
	}
}
@media(max-width: 1200px){
	.collumns{
		width: 100%!important;
	}
	.page-02-sobrepage .container{
		width: 100% !important;
		margin: 0;
		max-width: none;
	}
	.page-03-sobrepage .container{
		width: 100% !important;
		margin: 0;
		max-width: none;
	}
		.shape03{
			display: none;
		}
		.shape{
		display: none;
	}

	.page-02-sobrepage .row{
		margin: 0!important;

		.boxcontent .banner-cs{
			width: 73%;
		}
	}
	.brandscitrus{
		margin-right: 0!important;
	}
	.page-03-sobrepage .inner .row{
		margin: 0!important;
	}
}

@media(max-width: 600px) {
	.page-02-sobrepage{
		.box{
			padding: 40px 0 35px 0!important;
		}
		
	}
	.contpage{
		margin-bottom: 16px;
	}
	
	.boxcontent{
		flex-flow: column;
		padding-bottom: 35px;

		.brandscitrus{
			margin-top: 90px;
			a{
				width: 130px;

				img{

					margin-bottom: 24px!important;
				}
			}
		}
		
		.banner-cs{
			width: 100%!important;
			max-width: none!important;
		}
		.banner-cs-body{
			p{
				width: 100%;
				max-width: none!important;
			}
		}
	}
	.banner-cs-body{
		line-height: 178%;
		font-size: 16px;
		font-weight: 500;
	}
	.ld-sobrepage .page-03-sobrepage p{
		line-height: 178%;
		font-size: 16px!important;
		font-weight: 400;
	}
	.page-03-sobrepage .banner-cs{
		    margin-bottom: 20px;
	}
}

</style>
