<template>
  <div id="app" class="ci-app">
		<transition name="loading">
			<ci-loading
				v-if="
					loadingState != 'hide' &&
					(
						$route.name == 'home' ||
						$route.name == 'precos' ||
						$route.name == 'cadastro' ||
						$route.name == 'faq' ||
						$route.name == 'recursos' ||
						$route.name == 'comprar' ||
						$route.name == 'detalhes' ||
						$route.name == 'finalizado' ||
						$route.name == 'provisionamento' ||
						$route.name == 'termos' ||
						$route.name == 'sobre' ||
						$route.name == 'naoencontrado'
					)
				"
				class="ci-app__loading"
				:state="loadingState"
			/>
		</transition>
    
		<div class="ci-app__body">

			<md-project34 />
			
			<!--div class="bannerSquad" v-if="$route.name == 'home'">
				<img class="close" :src="publicPath + 'images/close.png'">
				<p>
					<img src="https://citrus.cx/wp-content/uploads/2020/02/new.png">
					<a target="_blank" href="https://materiais.citrus.cx/citrus-talk">
						<strong>Inscreva-se agora para o Citrus Talk</strong>, série de webinars on-line e gratuitos voltados para o segmento de saúde. 
						<button style="border: 1.5px solid rgb(164, 164, 164); cursor:pointer;text-align: center;font-size: 11px;background: transparent;color: #fff;padding: 6px 10px;font-weight: bold;letter-spacing: 1px;border-radius: 5px; margin: 0 8px">QUERO AGORA →</button>
					</a>
				</p>
			</div-->

			<ld-header
				v-if="$route.name != 'finalizado' && $route.name != 'provisionamento' && $route.name != 'solicitacao'"
				class="ci-app__header"
				:navigation="menu"
				:selected="selected"
				:lang="lang"
				@update:lang="onLangChange"
				@update:selected="onNavigationChange"
				@menu="sidenav = true"
				:class="[{ 'orange': $route.path == '/precos' || $route.path == '/detalhamentopacotes' || $route.path == '/comprar', 'white': $route.path == '/recursos' || $route.path == '/sobre' || $route.name == 'naoencontrado' || $route.path == '/faq' || $route.path == '/termos-de-uso' }]"
			/>

			<transition name="router" mode="out-in">
				<router-view
					class="ci-app__view"
					:scroll="scroll"
					v-if="initialized"
					:key="$route.path"
				/>
			</transition> 

			<ld-footer class="ci-app__footer" :navigation="menu" v-if="$route.name != 'finalizado' && $route.name != 'provisionamento' && $route.name != 'solicitacao' " />
		</div>

		<div @click="sidenav = false">
			<ld-aside
			class="ci-app__aside"
			:class="{ 'is-active': sidenav }"
			:selected="selected"
			:navigation="menu"
			@update:selected="onNavigationChange"
			@close="onSidenavClose"
		/>
		</div>
		

		<transition name="fade">
			<div class="ci-app__overlay"
				@click="sidenav = false"
				v-if="sidenav"
			>
			</div>
		</transition>
  </div>
</template>

<script>
import LDHeader from '@/components/organisms/LDHeader'
import LDFooter from '@/components/organisms/LDFooter'
import LDAside from '@/components/organisms/LDAside'
import LDHome from '@/components/pages/LDHome'
import ModalProject34 from '@/components/modals/Project34'
import CILoading from '@/components/molecules/CILoading'
import { disableBodyScroll } from 'body-scroll-lock'
import { TweenMax, Power2 } from 'gsap/TweenMax'
import { mapGetters } from 'vuex'
import $ from 'jquery'

require('../public/css/redefine.css');
require('../public/css/animate.css');

export default {
	components: {
		'ld-header': LDHeader,
		'ld-footer': LDFooter,
		'ci-loading': CILoading,
		'ld-aside': LDAside,
		'md-project34': ModalProject34,
    	// 'ci-alert': CIAlert
	},

	computed: {
		...mapGetters({
			contact: 'page/getContact',
			menu: 'menu/getMenu',
     		alert: 'alert/getCurrent',
			lang: 'getLang'
		})
	},

	data: () => ({
		initialized: false,
		selected: '',
		scroll: 0,
		sidenav: false,
		sections: [],
		scrollTimer: null,
		sending: false,
		loadingState: 'idle',
		brand: 'images/lemon-desk.png'
	}),
	mounted() {
		// Start loading
		this.loadingState = 'loading'
		if(this.$route.name === 'recursos' || this.$route.name === 'cadastro' || this.$route.name === 'faq'){
			$('.ci-app__header').addClass('white');
		} else {
			
		}
		$('.close').click(function(){
			$('.bannerSquad').slideUp('fast');
		})
		
		// $("#recursos").click(function(){
		// 	const container = document.querySelector('.ci-app__body')
		// 	let recursosTop = $("#beneficios").position().top - 100

		// 	container.scrollTo({
		// 		top: recursosTop,
		// 		behavior: 'smooth'
		// 	})
		// 	return
		// })
		// $("#sobrebt").click(function(){
		// 	const container = document.querySelector('.ci-app__body')
		// 	let sobreTop = $("#sobre").position().top - 100

		// 	container.scrollTo({
		// 		top: sobreTop,
		// 		behavior: 'smooth'
		// 	})
		// 	return
		// })
	},
	watch: {
		initialized(value) {
			if (value)
				this.$nextTick(this.init)
		}
	},

	created() {
		// Fetch pages data
		Promise.all([
			this.$store.dispatch('page/fetch'),
			this.$store.dispatch('menu/fetch')
		])
			.then(([pages, menu]) => {
				// Show the page
				this.initialized = true

				// Populates sections data
				this.sections = menu.map(item => item.link)
				this.sections.push('#solutions')

				// Delay to loading exit
				setTimeout(() => {
					this.loadingState = 'done'

					setTimeout(() =>
						this.loadingState = 'hide'
					, 500)
				}, 100)
			})
	},

	

	methods: {
		init() {
			// Listen to body's scroll
			this.$el.querySelector('.ci-app__body')
				.addEventListener('scroll', this.onScroll)

			// Prevent smartphone from sliding the entire viewport
			disableBodyScroll(this.$el.querySelector('.ci-app__body'))
		},

		validURL(str) {
			var pattern = new RegExp('^(https?:\\/\\/)?'+
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
				'((\\d{1,3}\\.){3}\\d{1,3}))'+
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
				'(\\?[;&a-z\\d%_.~+=-]*)?'+
				'(\\#[-a-z\\d_]*)?$','i')

			return !!pattern.test(str)
		},

		scrollToSection(section) {
			this.sidenav = false

			const scroll = (target) => {
				this.selected = section

				TweenMax.to(this.$el.querySelector('.ci-app__body'), 1, {
					scrollTop: target.offsetTop - 80,
					ease: Power2.easeOut,
				})
			}

			if (section.indexOf('#') != -1) { // If is an anchor
				let target = document.getElementById(section.replace('#', ''))
				if (target) return scroll(target)

				this.$router.push({ name: 'home' })

				setTimeout(() => {
					let target = document.getElementById(section.replace('#', ''))
					if (target) return scroll(target)
				}, 1000)

			} else if(this.validURL(section)) { // If is a link
				window.open(section, '_blank')

			}	else { // Otherwise try to find route
				this.$router.push(section)
			}
		},

		onNavigationChange(section) {
			this.scrollToSection(section)
		},

		onSidenavClose() {
			this.sidenav = false
		},

		onScroll(event) {
			clearTimeout(this.scrollTimer)

			this.scrollTimer = setTimeout(() => {
				// Update scroll
				this.scroll = event.target.scrollTop

				this.sections.forEach((section, index) => {
					let element = document.getElementById(section.replace('#', ''))

					if (element) {
						let offset = element.offsetTop,
							height = element.clientHeight,
							viewHeight = window.innerHeight / 2,
							scroll = this.scroll + viewHeight

						// if scrollTop is greater than section's offset
						// and less than the section's offset + height
						// this section must be activated
						if (scroll > offset && scroll < offset + height) {
							this.selected = section
						}
					}
				})
				if(this.scroll > 56) {
					$('.ci-header').addClass('white fixed');
					$('.brandwh').attr('src', this.publicPath + 'images/lemon-desk-dark.png')
				}else{
					$('.ci-header').removeClass('white fixed');
					$('.brandwh').attr('src', this.publicPath + 'images/lemon-desk.png')
				}
			}, 30)
			
		},

		onSocialClick(provider) {
			switch(provider) {
				case 'instagram':
					window.open('https://www.instagram.com/digivoxbr')
					break
				case 'linkedin':
					window.open('https://www.linkedin.com/company/digivoxbr')
					break
				case 'facebook':
					window.open('https://www.facebook.com/digivoxbr')
					break
				case 'twitter':
					window.open('https://twitter.com/digivoxbr')
					break
			}
		},

		onLangChange(lang) {
			switch(lang) {
				case 'en':
					window.location.href = process.env.EN_URL
					break
				case 'pt':
				default:
					window.location.href = process.env.PT_URL
					break
			}
		},
		goToContact () {
			this.$router.push( '/' );
			 $('#contact').animate({scrollTop:0}, '500', 'swing');
		}
	}
}
</script>

<style lang="scss">
.bannerSquad{
	background-image: linear-gradient(to right, #485062, #485062);
	color: #a1a1a1;
	padding: 7px 16px 7px;
	font-family: 'Poppins';
	font-size: 15px;
	text-align: center;
	position: relative;

	img.close{
		padding: 10px;
		position: absolute;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);
		cursor: pointer;
	}
	p{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0!important;

		a{
			text-decoration: none;
			color: #fff;
			margin: 12px;
		}
	}
}
body, html{
	height: 100%;
	overflow: hidden;
	font-family: 'Poppins'!important;
}
.ci-header.fixed{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
.ld_cta_pattern{
	background: #1ebabe;
    text-align: center;
    border: none;
    border-radius: 35px;
    color: #fff;
    padding: 15px 20px;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

	&:hover{
		box-shadow: 0px 0px 4px #2196f3;
	}
}
 .ld_cta_pattern_sec{
	background: #fff;
    text-align: center;
    border: none;
    border:solid 3px #1ebabe;
    border-radius: 35px;
    color: #1ebabe;
    padding:13px 24px;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;

	&:hover{
		box-shadow: 0px 0px 4px #2196f3;
	}
}
.ld_cta_yellow_pattern{
	background: #fdc522;
    text-align: center;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 16px 11px;
    font-family: 'Poppins';
    font-size: 15px;
    cursor: pointer;

	&:hover{
		box-shadow: 0px 0px 4px #fdc522;
	}
}
.ci-aside .ci-navigation .ci-navigation__label a{
	color: #666666!important;
}
.ci-app{
	display: flex;
	flex-flow: column;
	height: 100%;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	&__loading{
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 500;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}
	&__header{
		position: absolute;
		
		width: 100%;
		z-index: 40;
	}
	&__body{
		position: relative;
		flex: 1;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch;
	}
	&__aside{
		background: #ffffff;
		position: absolute;
		max-width: 100%;
		top: 0;
		right: -110%;
		bottom: 0;
		min-width: 280px;
		z-index: 99999999999999;
		transition: .5s $easeInOutQuad;
		&.is-active{
			right: 0;
		}
	}
	&__footer{
		flex: 1;
	}
	&__overlay{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#2B2D42, .4);
		z-index: 35;
	}
	&__alerts{
		position: absolute;
		top: 96px;
		right: 24px;
    	left: 24px;
		z-index: 500;
	}
	@include grid-media($g-tablet-up) {
		.ci-app{
			&__alerts{
				left: initial;
				width: 400px;
			}
		}
		/*.app_chat_button{
			width: 50px!important;
		}
		.app_chat_w .app_chat_i{
	        box-shadow: 0 2px 9px 0 rgba(210, 210, 210, 0.55);
	        border-radius: 10px;
	    
	        iframe{
	        	height: 470px;
	        }*/
		/*}*/
		.app_chat_w {
		    z-index: 9999;
		    position: fixed;
		    bottom: 60px;
		    right: 20px;
		   }
		   /*.portlet do chat{
		   	    padding: 10px 0;
		   }*/
	}
}
.ld_content_cta{
	text-align: center;

	p{
		margin-top: 10px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: all .4s $easeInOutQuad;
}

.fade-enter,
.fade-leave-active{
	opacity: 0;
}
a{
	text-decoration: none;
}
.cats{
    position: absolute;
    top: 4%;
    left: 3%;
    padding: 4px 13px 5px;
    border-radius: 4px;
    color: #fff !important;
	z-index: 9;

	a{
		color: #fff;
		font-size: 10px;
		font-family: 'montserrat';
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
	}
}
.catSingle{
    background-color: #b3c520;
    padding: 3px 13px 4px;
    border-radius: 4px;
    color: #fff !important;
    float: left;
    // margin-bottom: 5px;

	a{
		color: #fff;
		font-size: 10px;
		font-family: 'montserrat';
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
	}
}
.white a {
	color: #868a88 !important
}
.catPopular{
	background-color: #d0d1c7;
	padding: 3px 13px 4px;
	border-radius: 4px;
	color: #fff !important;
	float: left;
	margin-bottom: 5px;

	a{
		color: #fff;
		font-size: 10px;
		font-family: 'montserrat';
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
	}
}
	.mr-auto{
		margin-right: unset!important;
    	float: left!important;

		p{
			font-family: 'Poppins';
			color: #2B2D42;
			margin-top: 14px;
		}
	}
.jornada-do-cliente{
	background-color: #f4c800;
}
.vendas {
	background-color: #b2c039;
}
.customer-experience {
	background-color: #ffad0e;
}
.omnichannel{
	background-color: #e72a45;
}
.colaboracao{
	background-color: #5a93ba;
}
.moment{
	font-family: 'montserrat';
	font-size: 14px;
	line-height: 30px;
	margin-right: 30px;

	i{
		font-size: 12px!important;
		margin-right: 3px;
	}
}
.navblog.is-inline{
	flex-flow: column!important;
	align-items: flex-start;

	.ci-navigation__label{
		font-size: 25px;
		font-weight: bold;
	}
}
@media(min-width: 1024px){	
	.navblog.is-inline{
		flex-flow: row!important;
		
		.ci-navigation__label{
			font-size: 16px;
			font-weight: normal;
		}
	}
	.mr-auto{
		float: left;

		p{
			font-family: 'Poppins';
			color: #2B2D42;
		}
	}

	.ml-auto{
		float: right;
	}
}

@media(max-width: 768px){
	.ld_cta_pattern {
	    font-size: 16px!important;
	   }
}
@media(max-width: 600px){
	.ld_cta_pattern, .ld_cta_pattern_sec{
		width: 100%;
		margin: 0 0 10px 0;
	}
}
</style>
