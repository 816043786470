<template>
	<div class="ld-pesquisa"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ld-pesquisa__container">
			<agile :dots="false" :navButtons="false" :autoplay="true" :autoplay-speed="6000" :speed="600" fade="fade" pause-on-hover="pause-on-hover" ref="carousel">
				<div class="slide in slides">
					<div class="ld-pesquisa__plot">
						<img class="" :src="`${publicPath}images/pesquisa.png`">
					</div>
					<div class="ld-pesquisa__content">
						<ci-text class="ld-pesquisa__title">Escute a voz do seu cliente</ci-text>
						<ci-text class="ld-pesquisa__text">Crie pesquisas de satisfação personalizadas e estimule seu cliente a dar feedbacks sobre o seu negócio para ajudar em melhorias que ofereçam o melhor atendimento.</ci-text>
						<div class="arrowContainer">
							<button @click="$refs.carousel.goToPrev()" class="buttonPrev"><i class="fas fa-chevron-left"></i></button>
							<button @click="$refs.carousel.goToNext()" class="buttonNext"><i class="fas fa-chevron-right"></i></button>
						</div>
					</div>

				</div>
				<div class="slide">
					<div class="ld-pesquisa__plot">
						<img class="" :src="`${publicPath}images/chatbot.png`">
					</div>

					<div class="ld-pesquisa__content">

						<ci-text class="ld-pesquisa__title">Surpreenda com respostas rápidas</ci-text>
						<ci-text class="ld-pesquisa__text">Chatbots treinados solucionam demandas complexas de maneira humanizada e direcionam a conversa para um atendente, quando necessário, através de automação.</ci-text>
						<div class="arrowContainer">
							<button @click="$refs.carousel.goToPrev()" class="buttonPrev"><i class="fas fa-chevron-left"></i></button>
							<button @click="$refs.carousel.goToNext()" class="buttonNext"><i class="fas fa-chevron-right"></i></button>
						</div>
					</div>
					
				</div>

				<div class="slide">
					<div class="ld-pesquisa__plot">
						<img class="" :src="`${publicPath}images/jornada-do-cliente.png`">
					</div>

					<div class="ld-pesquisa__content">
						<ci-text class="ld-pesquisa__title">Não perca o seu cliente de vista</ci-text>
						<ci-text class="ld-pesquisa__text">Acompanhe todos os pontos de contato da jornada do cliente e não o faça responder duas vezes a mesma pergunta. De forma prática e visual, reduza seu tempo de atendimento.</ci-text>
						<div class="arrowContainer">
							<button @click="$refs.carousel.goToPrev()" class="buttonPrev"><i class="fas fa-chevron-left"></i></button>
							<button @click="$refs.carousel.goToNext()" class="buttonNext"><i class="fas fa-chevron-right"></i></button>
						</div>
					</div>
					
				</div>
			</agile>
			<router-link to="/recursos"><button class="ld_cta_pattern thisBt">VEJA MAIS RECURSOS</button></router-link>			
		</div>		
	</div>
</template>

<script>
import CICircle from '@/components/atoms/CICircle'
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'
import { VueAgile } from 'vue-agile'

export default {
	components: {
		'ci-text': CIText,
	 	'agile': VueAgile
	},
	props: {
		title: {
			type: String,
			default: 'Converse com seu cliente por onde ele preferir '
		},
		content: {
			type: String,
			default: 'Através de uma completa suíte de comunicação, o Lemon Desk permite o atendimento aos clientes, por meio de qualquer canal de comunicação, em uma única ferramenta. '
		},
		
		initialized: {
			type: Boolean,
			default: true
		},

		slides: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}
			])
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.agile__slide{
	height: 100%!important;
	/*border: solid 1px green;*/
}
.ld-pesquisa{
	position: relative;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	position: relative;
	z-index: 30;
	padding: 2vh 0 4vh;
	background: #ffffff;
	overflow: hidden;
	/*height: calc(100vh - 55px);*/
	
	a{
		text-decoration: none;
		color: #fff;

		&:hover{
			text-decoration: none;
			color: #fff;
		}
	}
		
	&__container{
		width: 100%;
		@include outer-container;
		flex-flow: column wrap;		
		/*border: solid 1px green;*/

		a{
			text-decoration: none;
			color: #fff;
		}
	}
	&__content{	
		@include grid-column(6);
		float: left;
		margin-left: 16px;
		margin-left: 0;
		text-align: center;
		/*height: 40vh;*/
		height: 38vh;
		display: flex;
		position: relative;
		flex-flow: column;
		place-content: flex-end;
		
		.arrowContainer{
			/*position: absolute;
			bottom: 0;*/
			width: 100%;
			margin: 0 auto;
			text-align: center;
			/*position: absolute;
			bottom: 0;*/
		}
		.buttonNext, .buttonPrev{
			width: 45px;
			height: 45px;
		}
	}	
	
	&__title{
		margin: 0 0 15px;
		/*font-size: 32px;*/
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 1px;
		color: #272727;
		font-weight: 600;
	}
	&__text{
		color: #555353;
		font-size: 17px;
		line-height: 150%;
		margin: 0 auto 22px;
		max-width: 377px;
	}
	
	&__plot{
		@include grid-column(6);
	    margin-left: 16px;
	    margin: 12px 0;
	    height: 30vh;

		img{
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	@include grid-media($g-tablet-up) {
		.slide{
			display: block;
			height: auto;
			-o-object-fit: cover;
			object-fit: cover;
			width: auto;
			/*border: solid 1px red;*/
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;

		}
		&__title{
			margin-bottom: 11px!important;
		}
		&__content{		
			width: 35%;
			text-align: left!important;
			height: 290px;
			position: relative;

			.arrowContainer{/*
				position: absolute;
				bottom: 0;*/
				left: 0;
				text-align: left;

				.buttonNext, .buttonPrev{
					width: 42px;
					height: 42px;
				}
			}
		}	
		&__plot{
			width: 35%;
			float: left;
			height: auto;
			
			img{
				width: 100%;
			}
		}
		&__text{
			margin: 0 0 22px 0;
		}
	}
	@include grid-media($g-desktop-up) {
		padding: 8vh 0;
		flex-flow: row wrap;

	}
}

.slide {
	display: block;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	width: auto;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-evenly;
}
.agile{
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.agile__nav-button {
	background: #fcc533;
	border: none;
	color: #fff;
	cursor: pointer;
	font-size: 24px;  
	top: 0;
	transition-duration: 0.3s;
	width: 45px;
	height: 45px;
	border-radius: 100%;
	text-align: center;
    line-height: 33px;
    position: relative!important;
}

.thisBt{
	clear: both!important;
	display: flex;
	margin: 0 auto;
	
}
.buttonPrev{
	width: 45px;
	height: 45px;
	background-color: #fcc533;
	border-radius: 100px;
	text-align: center;
	color: #fff;
	border: none;
	box-shadow: none;
	cursor: pointer;
	margin-right: 10px;

    font-size: 9px;
}
.buttonNext{
	width: 45px;
	height: 45px;
	background-color: #fcc533;
	border-radius: 100px;
	text-align: center;
	color: #fff;
	border: none;
	box-shadow: none;
	cursor: pointer;
	margin: 0 5px;
	font-size: 9px;
}
.agile{position:relative}.agile--rtl .agile__actions,.agile--rtl .agile__dots,.agile--rtl .agile__slides,.agile--rtl .agile__track{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}.agile:active,.agile :active,.agile:focus,.agile :focus{outline:none}.agile__list{display:block;overflow:hidden;position:relative;width:100%}.agile__track{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:nowrap;flex-wrap:nowrap}.agile__actions,.agile__track{display:-webkit-box;display:-ms-flexbox;display:flex}.agile__actions{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.agile__slides{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:0;flex-shrink:0;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}.agile--disabled .agile__slides{display:block}.agile__slide{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:0;flex-shrink:0}.agile--fade .agile__slide{opacity:0;position:relative;z-index:0}.agile--fade .agile__slide--active{opacity:1;z-index:2}.agile--fade .agile__slide--expiring{opacity:1;-webkit-transition-duration:0s;transition-duration:0s;z-index:1}.agile__nav-button[disabled]{cursor:default}.agile__dots{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;list-style:none;padding:0;white-space:nowrap}.agile__dot button{cursor:pointer;display:block;font-size:0;line-height:0}


</style>
