<template>
	<div class="ci-button-icon"
		:class="[
			`ci-button-icon--${theme}`,
			`ci-button-icon--${size}`,
		]"
		@click="$emit('click', $event)"
	>
		<span class="ci-button-icon__icon"
			:class="[
				name
			]"
		>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: 'close'
		},
		theme: {
			type: String,
			default: 'primary'
		},
		size: {
			type: String,
			default: 'md' // sm | md | lg
		},
	}
}
</script>

<style lang="scss">
.ci-button-icon{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	background: $c-darkgray;
	cursor: pointer;
	transition: .2s $easeInOutQuad;
	&__icon{
		color: white;
	}

	// Sizes
	&--sm{
		width: 40px;
		height: 40px;
		margin-right: 6px;
		span{
			font-size: 20px;
		}
	}
	&--md{
		width: 62px;
		height: 62px;
		span{
			font-size: 22px;
		}
	}
	&--lg{
		width: 80px;
		height: 80px;
		span{
			font-size: 32px;
		}
	}

	// Themes
	&--primary{
		background: $t-primary;
		&:hover{
			background: $t-accent;
		}
	}
	&--accent{
		background:#1ebabe;
		&:hover{
			box-shadow: 0px 0px 8px #1ebabe;
		}
	}
	&--warn{
		background: $t-warn;
		&:hover{
			background: saturate($t-warn, 10%);
		}
	}

	&:hover{
	}
}
</style>
