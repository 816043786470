circle
s<template>
	<div class="ld-benefit"
		:class="[
			`ld-benefit--${theme}`
		]"
	>
		<figure class="ld-benefit__circle">
			<img class="ld-benefit__icon" :src="icon">
		</figure>
		<ci-text class="ld-benefit__comment">
			{{description}}
		</ci-text>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		icon: {
			type: String,
			default: 'images/icon-finance.png'
		},
		description: {
			type: String,
			default: 'Defaulting'
		},
		theme: {
			type: String,
			default: 'primary'
		}
	}
}
</script>

<style lang="scss">
.ld-benefit{
	
	&__circle{
		width: 85px;
		height: 85px;
		border-radius: 100%;
		background: #ffffff;
		border: solid 1.5px #1ebabe;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__comment{
		font-family: 'Poppins';
		display: block;
		font-size: 15px;
		line-height: 125%; 
		font-weight: normal;
		margin: 20px auto 0;
		max-width: 205px;
		color: #5b5c61;
	}

	@include grid-media($g-tablet-up) {
		&__comment{
			font-family: 'Poppins';
			display: block;
			font-size: 15px;
			line-height: 125%; 
			font-weight: 500;
			margin-top: 20px;
		}
	}
}
</style>
