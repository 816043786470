var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ci-app",attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"loading"}},[(
					_vm.loadingState != 'hide' &&
					(
						_vm.$route.name == 'home' ||
						_vm.$route.name == 'precos' ||
						_vm.$route.name == 'cadastro' ||
						_vm.$route.name == 'faq' ||
						_vm.$route.name == 'recursos' ||
						_vm.$route.name == 'comprar' ||
						_vm.$route.name == 'detalhes' ||
						_vm.$route.name == 'finalizado' ||
						_vm.$route.name == 'provisionamento' ||
						_vm.$route.name == 'termos' ||
						_vm.$route.name == 'sobre' ||
						_vm.$route.name == 'naoencontrado'
					)
				)?_c('ci-loading',{staticClass:"ci-app__loading",attrs:{"state":_vm.loadingState}}):_vm._e()],1),_c('div',{staticClass:"ci-app__body"},[_c('md-project34'),(_vm.$route.name != 'finalizado' && _vm.$route.name != 'provisionamento' && _vm.$route.name != 'solicitacao')?_c('ld-header',{staticClass:"ci-app__header",class:[{ 'orange': _vm.$route.path == '/precos' || _vm.$route.path == '/detalhamentopacotes' || _vm.$route.path == '/comprar', 'white': _vm.$route.path == '/recursos' || _vm.$route.path == '/sobre' || _vm.$route.name == 'naoencontrado' || _vm.$route.path == '/faq' || _vm.$route.path == '/termos-de-uso' }],attrs:{"navigation":_vm.menu,"selected":_vm.selected,"lang":_vm.lang},on:{"update:lang":_vm.onLangChange,"update:selected":_vm.onNavigationChange,"menu":function($event){_vm.sidenav = true}}}):_vm._e(),_c('transition',{attrs:{"name":"router","mode":"out-in"}},[(_vm.initialized)?_c('router-view',{key:_vm.$route.path,staticClass:"ci-app__view",attrs:{"scroll":_vm.scroll}}):_vm._e()],1),(_vm.$route.name != 'finalizado' && _vm.$route.name != 'provisionamento' && _vm.$route.name != 'solicitacao' )?_c('ld-footer',{staticClass:"ci-app__footer",attrs:{"navigation":_vm.menu}}):_vm._e()],1),_c('div',{on:{"click":function($event){_vm.sidenav = false}}},[_c('ld-aside',{staticClass:"ci-app__aside",class:{ 'is-active': _vm.sidenav },attrs:{"selected":_vm.selected,"navigation":_vm.menu},on:{"update:selected":_vm.onNavigationChange,"close":_vm.onSidenavClose}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.sidenav)?_c('div',{staticClass:"ci-app__overlay",on:{"click":function($event){_vm.sidenav = false}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }