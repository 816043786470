<template>
	<svg
		class="ci-leaf"
		:class="[
			`ci-leaf--${theme}`,
			{
				'is-filled': filled
			}
		]"
		width="967"
		height="847"
		viewBox="0 0 967 847"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<clipPath :id="`leaf-mask-${id}`">
				<rect x="0" :y="`${100-mask}%`" width="100%" height="100%" />
			</clipPath>
		</defs>
		<path d="M269.698 691.14C-66.3022 425.674 6.69786 42.1406 6.69786 42.1406C6.69786 42.1406 388.698 -107.859 707.698 158.141C1026.7 424.141 960.698 801.14 960.698 801.14C960.698 801.14 605.698 956.606 269.698 691.14Z" fill="transparent" stroke-width="6" :clip-path="`url(#leaf-mask-${id})`"/>
	</svg>
</template>

<script>
export default {
	props: {
		theme: {
			type: String,
			default: 'primary'
		},
		filled: {
			type: Boolean,
			default: true
		},
		mask: {
			type: Number|String,
			default: 100
		}
	},

	computed: {
		id() {
			return this._uid
		}
	}
}
</script>

<style lang="scss">
.ci-leaf{
	path{
		transform: scale(0.9) translate(20px, 20px);
	}
	&--primary{
		path{
			stroke: #fcb80d;
		}
		&.is-filled{
			path{
				fill: #fcb80d;
			}
		}
	}
	
}
</style>
