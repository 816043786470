<template>
	<div class="ci-lang" v-if=" 
					  this.$route.name !== 'blog' && 
					  this.$route.name !== 'post' && 
					  this.$route.name !== 'categoria'">
		<div class="ci-lang__picker" tabindex="0" @focus="active = true" @blur="active = false">
			<img
				class="ci-lang__flag"
				:src="publicPath + current.flag"
				:alt="current.label"
			>
			<ci-text
				class="ci-lang__lang"
				type="caption"
				:bold="true"
			>
				{{ value }}
			</ci-text>
			<span
				class="ci-lang__arrow fa fa-angle-down"
			/>
		</div>

		<nav class="ci-lang__nav" :class="{'is-active': active}">
			<ul class="ci-lang__list">
				<li class="ci-lang__item" v-for="(item, lang) in items">
					<button
						class="ci-lang__option"
						@click.stop="onChange(lang)"
					>
						<img
							class="ci-lang__flag"
							:src="publicPath + item.flag"
							:alt="item.label"
						>
						<ci-text
							class="ci-lang__lang"
							type="caption"
							:bold="value == lang"
						>
							{{ item.label }}
						</ci-text>
					</button>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},

	props: {
		items: {
			type: Object,
			default: () => ({
				pt: {
					label: 'Português',
					flag: 'images/flag-pt.svg'
				},
				en: {
					label: 'Inglês',
					flag: 'images/flag-en.svg'
				},
			})
		},
		value: {
			type: String,
			default: 'pt'
		}
	},

	computed: {
		current() {
			return this.items[this.value] || {}
		}
	},

	data: () => ({
		active: false
	}),

	methods: {
		onChange(lang) {
			this.$emit('input', lang)
			this.active = false
		}
	}
}
</script>

<style lang="scss">
.ci-lang{
	position: relative;
	&__picker{
		cursor: pointer;
		display: flex;
		align-items: center;
		background: transparent;
		border: 0;
		outline: none;
	}
	&__lang{

	}
	&__arrow{
		margin-left: 4px;
		color: $c-gray;
	}
	&__nav{
		position: absolute;
		top: 70%;
		right: 0%;
		background: white;
		padding: 16px 0;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(black, .06);
		transition: .3s $easeInOutQuad;
		opacity: 0;
		visibility: hidden;
		&.is-active{
			top: 100%;
			opacity: 1;
			visibility: visible;
		}
	}
	&__list{
		margin: 0;
		padding: 0;
	}
	&__item{
		list-style: none;
	}
	&__option{
		cursor: pointer;
		display: flex;
		align-items: center;
		background: transparent;
		border: 0;
		outline: none;
	}
	&__flag{
		width: 24px;
		margin-right: 8px;
	}
}
</style>
