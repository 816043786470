<template>
	<div class="ld-consultasrapidas">
			<img class="ld-consultasrapidas__shape" :src="`${publicPath}images/shape-hero-03.png`">
		<div class="ld-consultasrapidas__container">
			
			<div class="ld-consultasrapidas__mock onlymobile">				
				<img class="mock03" :src="`${publicPath}images/mock-03.png`">
			</div>
			<div class="ld-consultasrapidas__content">
	
				<ci-text class="ld-consultasrapidas__title" type="title">
					Informações essenciais do paciente e exames disponíveis<span> a um clique</span>
					</ci-text>
					<ci-text class="ld-consultasrapidas__text ld-consultasrapidas__animate" type="lead">
						Aproveite a tranquilidade da vídeo consulta com pacientes agendados e transferência para sala médica, sem se preocupar com aglomeração ou atrasos. Além de ter todas as informações que precisa disponível na palma da sua mão.
					</ci-text>
					<div class="ld_content_cta mr-auto thisonectas">
						<router-link to="/recursos"><button class="bla ld_cta_pattern">Saiba mais</button></router-link>
					</div>
					<!-- <ul class="consultas_list">
                        <li class="consultas_list-item"><i class="fa fa-check"></i>Sala fixa de reunião </li>
                        <li class="consultas_list-item"><i class="fa fa-check"></i>Agendamento de conferências</li>
                        <li class="consultas_list-item"><i class="fa fa-check"></i>Gravação das reuniões</li>
                        <li class="consultas_list-item"><i class="fa fa-check"></i>Mudança de layout das telas</li>
                    </ul> --><!-- /.cta-three__list -->
					
			</div>
			<div class="ld-consultasrapidas__mock onlyDesk">				
				<img class="mock03" :src="`${publicPath}images/mock-03.png`">
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		mock: {
			type: String,
			default: undefined
		},
		thumbnail: {
			type: String,
			default: undefined
		},
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: 'Busca uma forma prática e eficiente de otimizar seu atendimento? Quer fazer tudo isso e ainda vender muito mais? '
		},
		theme: {
			type: String,
			default: 'accent'
		},
		initialized: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ld-consultasrapidas{
	position: relative;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 30;
    padding: 6vh 0;
	&__shape{
	    display: none;
	}
	&__container{
		width: 100%;
		display: flex;
    	align-items: center;
    	flex-flow: wrap;
	}
	&__mock{
			
			@include grid-column(6);

			img{
				width: 100%
			}
		}
		&__content{
			@include grid-column(6);
		}
		&__title{
			color: #666666;
			float: left;
			font-size: 24px;
			line-height: 120%;
			margin-top: 18px;
			font-weight: 600;

			span{
				font-size: 30px;
				font-weight: bold;
				color: #1ebabe;
			}
		}
		&__text{
			color: #64666b;
		    font-size: 16px;
		    line-height: 150%;
		    float: left;
		    font-weight: 500;
		    letter-spacing: 0.5px;
		    margin: 17px 1px;
		}
	
	.consultas_list{
		width: 100%;
		float:left;
		list-style: none;
		padding-left: 0;

		li{
			color: #64666b;
			font-size: 17px;
			line-height: 205%;
			float: left;
			font-weight: 500;
			width: 100%;
			letter-spacing: 0;
			i{
				margin-right: 10px;
				color: #a2e9e7
			}
		}
	}
		@include grid-media($g-tablet-up){

			&__mock{
				
				@include grid-column(6);

				img{
					width: 100%
				}
			}
			&__content{
				@include grid-column(6);
			}
			&__title{
				color: #666666;
				float: left;
				font-size: 26px;
				line-height: 120%;
				
				font-weight: 600;

				span{
					font-size: 30px;
					font-weight: bold;
					color: #1ebabe;
				}
			}
			&__text{
				color: #64666b;
				font-size: 17px;
				line-height: 150%;
				float: left;
				font-weight: 500;
				letter-spacing: 0.5px;
			}
		&__text{
			color: #64666b;
			font-size: 17px;
			line-height: 150%;
			float: left;
			font-weight: 500;
			letter-spacing: 0.5px;
		}

	}
	@include grid-media($g-desktop-up){
		padding: 10vh 0;
		position: relative;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		&__shape{
			position: absolute;
			left: -322px;
			top: 23%;
			display: block;
			z-index: -3 !important;
		}
		&__mock{

				float: left;
				width: 52%;	

				img{
					width: 100%;
				}
			}
			&__content{
				width: 28%;
    			margin-left: 11%;
				float: left;
			}
			&__container{
	    		display: flex;
	    		align-items: center;
	    		justify-content: space-between;
			}
			&__title{

				font-size: 32px;
				line-height: 120%;
				
				font-weight: 600;

				span{
					font-size: 37px;
					font-weight: bold;
					color: #1ebabe;
				}
			}
			&__text{
				margin: 18px 0 22px!important;
				font-size: 17px;
			}
	}
}
@media(max-width: 767px){
		.ld-consultasrapidas{
			@include grid-container;
		}
		.ld-consultasrapidas__container{
			@include outer-container;
		}
		.onlymobile{
			display: block;
		}
		.onlyDesk{
			display: none;
		}
	}
	@media(min-width: 768px){
		.onlymobile{
			display: none;
		}
		.onlyDesk{
			display: inline-flex!important;
		}
	}
</style>
