<template>
<div>
	 
	<footer class="ci-footer">
		
		<div class="ci-footer__container">
			<div class="socialnetworks">
				<div class="sitemap">
					<div class="coluna_site_map">
						<h1>A empresa</h1>
						<ul>
							<router-link to="/sobre"><li>Sobre</li></router-link>
							<a href="http://citrus.cx/blog"><li>Blog</li></a>
						</ul>
					</div>
					<div class="coluna_site_map">
						<h1>Produto</h1>
						<ul>
							<router-link to="/recursos"><li>Recursos</li></router-link>
							<!-- <li>Preços</li> -->
						</ul>
					</div>
					<div class="coluna_site_map">
						<h1></h1>
						<ul>
							<li></li>
						</ul>
					</div>
					<!-- <div class="coluna_site_map">
						<h1>Termos</h1>
						<ul>
							<li>Sobre</li>
							<li>Blog</li>
						</ul>
					</div>
					<div class="coluna_site_map">
						<h1>Políticas</h1>
						<ul>
							<li>Sobre</li>
							<li>Blog</li>
						</ul>
					</div>
 -->
					<div class="righting">
						<h1>Siga nossas redes sociais</h1>
				        <nav class="ci-b-footer__navigation ">
				        	<ci-button-icon
				                v-for="item in social"
				                class="ci-mapping__social"
				                :name="item.icon"
				                theme="accent"
				                size="sm"
				                @click="onSocialClick(item)"
				            />
				        </nav>
				      	<ci-text class="bfooterTyping" v-html="$t('footer.group')" />
				      	<ci-text class="bfooterTyping">Powered by <a href="http://citrus.cx">Citrus</a></ci-text>
				    </div>
				</div>
				

			</div>
		
		</div>
	</footer>
</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIButtonIcon from '@/components/atoms/CIButtonIcon'
import CIBrand from '@/components/atoms/CIBrand'
import $ from 'jquery'

export default {
	components: {
		'ci-text': CIText,
		'ci-button-icon': CIButtonIcon,
	},

	props: {
		navigation: {
			type: Array,
			default: () => ([])
		},
		social: {
			type: Array,
			default: () => ([
				{
					icon: 'fab fa-instagram',
					link: 'https://www.instagram.com/webdoctor.app'
				},
				{
					icon: 'fab fa-linkedin',
					link: 'https://www.linkedin.com/company/webdoctorpx'
				},
				{
					icon: 'fab fa-facebook-f',
					link: 'https://www.facebook.com/webdoctorapp/'
				},
				{
					icon: 'fab fa-twitter',
					link: 'https://twitter.com/webdoctorapp'
				},
				{
					icon: 'fab fa-youtube',
					link: 'https://www.youtube.com/channel/UCm7vydpsuozaJOb4qAdHDZA/videos?view_as=subscriber'
				},
			])
		}
	},

	methods: {
		onSocialClick(item) {
			window.open(item.link, '_blank')
		},
		backTop(){
            $(document.body).animate({
                scrollTop: '0px'
            }, 400, 'linear')
        }
	}
}
</script>

<style lang="scss">
.back-top {
        font-size: 30px;
        position: fixed;
        bottom: 50px;
        right: 40px;
        top: auto;
        cursor: pointer;
        z-index: 999;
        color: rgba(0, 0, 0, 0.6);
        width: 100px;
        height: 100px;
        border: solid 1px red;
    }

    .back-top:hover {
        color: rgba(0, 0, 0, 0.8);
        transition: all 0.5s ease;
    }

    .back-top-enter-active, .back-top-leave-active {
        transition: opacity .5s
    }

    .back-top-enter, .back-top-leave-active {
        opacity: 0
    }
.ci-footer{
	border-bottom: 10px solid #62ccd0;
	background: #65666d;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	padding: 39px 0!important;
	color: #fff!important;

	.contatocomercial{
		text-align: right;

		a{
			text-decoration: none!important;
			color: #007bff!important;
		}
	}
	&__container{
		width: 100%;
		text-align: left;
		@include outer-container;
		font-size: 15px!important;
		display: flex;
		flex-flow: column;
		position: relative;

		.coluna_site_map{
			@include grid-column(3);

			h1{
				font-weight: bold;
				color: #45c1c4;
				font-size: 16px;
			}
			ul{
				padding: 0;
				list-style: none;

				li{

				}
			}
		}
		.footerbottom{
			width: 100%;
			padding: 0 16px;
			text-align: left;

			a{
				text-decoration: none;
				color: #45c1c4;
				margin-bottom: 14px;
				display: block;
			}
		}
		
		.sitemap{
			@include grid-column(6);

			a{
				font-size: 95%;
				color: #fff;
				font-family: 'Poppins';
				text-decoration: none!important;
			}
		}
		.righting{
			@include grid-column(6);
			margin-top: 20px;

			h1{
				font-size: 16px;
				line-height: 167%;
				text-align: left;
				font-weight: normal;
			}
		}

	}
	&__copy{
		margin-top: 8px;
		@include grid-column(6);
	
	}

	&__info{
		margin-top: 8px;
		@include grid-column(6);
	}

	.socialnetworks{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 100%;
    	justify-content: space-between;
    	align-items: flex-start;
    	

		.ci-b-footer__navigation{
			display: flex;

    margin-bottom: 5px;
		}
		.bfooterTyping{
			color: #fff;
			line-height: 26px;
			margin: 8px 0;
			font-size: 14px;
			font-weight: 500;
			a{
				text-decoration: none;
				color: #62ccd0;

				&:hover{
					text-decoration: none;
					color: #62ccd0;
				}
			}
		}
	}
	@include grid-media($g-tablet-up) {
		padding: 0;
		.sitemap{
			@include grid-column(12);
		}
		.righting {
			@include grid-column(12);
			margin-top: 0px;
			h1{
				text-align: left;
			}
			nav{
				justify-content: flex-start;
			}
			.bfooterTyping{
				text-align: left;
				margin: 1px 0;
			}
		}
		.ci-footer{
			height: 120px;
			padding: 32px 0;    
			&__container{
				display: flex;
				align-items: center;
				flex-flow: column;
			}
			&__copy{
				order: 1;
				text-align: left;
				margin-top: 0;
				@include grid-column(12);
			}
			&__info{
				order: 1;
				text-align: right;
				margin-top: 0;
				@include grid-column(12);
			}
		}
	}
	@include grid-media($g-desktop-up) {
		.footerbottom{
			text-align: right;
			padding: 0 0px;
		}
		.footertop{
			padding: 0;
		}
		.socialnetworks{
			padding: 0;
		}
		.sitemap{
			@include grid-column(12);
			display: flex;
			align-items: center;
		}
		.coluna_site_map{
			@include grid-column(3);

			h1{
				font-weight: bold;
				color: #45c1c4;
				font-size: 16px;
			}
			ul{
				padding: 0;
				list-style: none;

				li{

				}
			}
		}
		.righting {
			@include grid-column(4);
			margin-top: 0px;
			h1{
				text-align: right;
			}
			nav{
				justify-content: flex-end;
			}
			.bfooterTyping{
				text-align: right;
				margin: 1px 0;
			}
		}
		.ci-footer{
			padding: 10px 0!important;
			&__container{
				display: flex;
				align-items: center;
			}
			&__copy{
				order: 1;
				text-align: left;
				margin-top: 0;
				@include grid-column(6);
			}
			&__info{
				order: 1;
				text-align: right;
				margin-top: 0;
				@include grid-column(6);
			}
		}
	}
}
</style>
