<template>
	<div class="ci-navigation"
		:class="[
			`ci-navigation--${size}`,
			{
				'is-inline': inline
			}
		]"
	>
		<div class="ci-navigation__option">
			<ci-text class="ci-navigation__label" id="recursos">
				<router-link to="/recursos">Recursos</router-link>
			</ci-text>
		</div>
		<!-- <div class="ci-navigation__option">
			<ci-text class="ci-navigation__label" id="benebt">
				Benefícios
			</ci-text>
		</div> -->
		<div class="ci-navigation__option">
			<ci-text class="ci-navigation__label" id="sobrebt">
				<router-link to="/sobre">Conheça</router-link>
			</ci-text>
		</div>
		
		<a href="http://materiais.citrus.cx/midas-webdoctor" target="_blank" class="rightnow"><button class="brightbutton">Cliente Midas</button></a>
		<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank" class="rightnow"><button>CADASTRE-SE</button></a>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import $ from 'jquery'
import { TweenMax, Power2 } from 'gsap/TweenMax'
export default {
	components: {
		'ci-text': CIText
	},

	props: {
		value: {
			type: String|Number,
			default: 'home'
		},
		inline: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			default: () => ([])
		},
		size: {
			type: String,
			default: 'md' // md | lg
		}
	},
	mounted () {
		// $(".ci-navigation__option").click(function(){
		// 	$(".ci-navigation__option").removeClass("active");
		// 	$(this).addClass("active");
		// })
		
		$("#sobrebt").click(function(){
			var bla = $("#sobre").position().top
			TweenMax.to(document.querySelector('.ci-app__body'), 1, {
				scrollTop: bla - 120,
				ease: Power2.easeOut,
			})
		});

		$("#benebt").click(function(){
			var topp = $("#beneficios").position().top
			TweenMax.to(document.querySelector('.ci-app__body'), 1, {
				scrollTop: topp,
				ease: Power2.easeOut,
			})

		});
	}
}
</script>

<style lang="scss">
	.white .brightbutton{
		border-color: #1cbbc0!important;
		color: #1cbbc0!important;
		background: #fff!important;
	}
	.ci-navigation__child{
		color: #666666!important;
	}
	.brightbutton{
		background: transparent!important;
	    color: #fff!important;
	    border-radius: 25px;
	    border: solid 2.5px white!important;
	    padding: 8px 20px!important;	    
	    text-transform: uppercase;
	    font-weight: 600!important;
	}
	.brightbutton:hover{
		box-shadow: 0px 0px 4px #c1c1c1;
	}
	.rightnow{
		display: none;
	}
.ci-navigation{
	display: flex;
	flex-flow: column;

	.ci-navigation__label a{
		color: #fff;
		font-weight: 500;

		&:hover{
			text-decoration: none;
		}
	}
	&__option{
		padding: 8px 18px;
		background: transparent;
		border: 0;
		text-align: left;
		outline: none;
		cursor: pointer;
		position: relative;
		
		&.is-active{
			color: white;
			&:after{
				width: 62px;
				height: 3px;
			}
		}
		&:after{
			content: "";
			position: absolute;
			width: 0px;
			bottom: 0;
			left: 16px;
			height: 2px;
			border-radius: 5px;
			background: #fff!important;
			transition: .3s $easeInOutQuad;
		}
	}

	.active{
		&:after{
			content: "";
			position: absolute;
			width: 40px;
			bottom: 0;
			left: 16px;
			height: 2px;
			border-radius: 5px;
			background: #2196f3!important;
			transition: .3s $easeInOutQuad;
		}
	}
	&__label{
		position: relative;
		display: inline-block;
		font-size: 18px!important;
	    font-weight: 500!important;
	}
	&__tooltip{
		background: rgba($t-accent, 1);
		position: absolute;
		border-radius: 4px;
		text-align: center;
		white-space: nowrap;
		padding: 2px 4px 0;
		left: 100%;
		top: 50%;
		transform: translate(16px, -50%);
		animation-name: nav-tooltip;
		animation-iteration-count: infinite;
		animation-duration: 4s;
		box-shadow: 0 2px 4px rgba(black, .05);
		&:before{
			content: "";
			position: absolute;
			width: 0; 
			height: 0; 
			top: 50%;
			left: initial;
			right: 100%;
			transform: translate(0, -50%);
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-right: 4px solid #ffe827!important;
		}
	}

	&--lg{
		&:not(.is-inline){
			.ci-navigation{
				&__option{
					margin-bottom: 8px;
				}
			}
		}
	}

	&.is-inline{
		flex-flow: row;
		align-items: center;
		.ci-navigation{
			&__tooltip{
				top: initial;
				bottom: 100%;
				left: 50%;
				transform: translate(-50%, -4px);
				animation-name: nav-tooltip-inline;
				&:before{
					top: 100%;
					left: 50%;
					transform: translate(-50%, 0);
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 4px solid #ffe827!important;
				}
			}
			&__option{
				&:after{
					left: 50%;
					transform: translateX(-50%);
					height: 3px;
				}
			}
		}
	}

	@include grid-media($g-desktop-up){
		.rightnow{
			max-width: 155px;
			display: block;
			margin-left: 12px;
			button{
				background: #fff;
				color: #1cbbc0;
				border-radius: 20px;
				border: none;
				padding: 10px 24px;
				font-weight: 500;
				font-size: 15px;
				outline: none;
				box-shadow: 0px 1px 7px -3px #7ab3b5;

				transition: .3s $easeInOutQuad;
			    &:hover{
				box-shadow: 0px 1px 7px -2px #7ab3b5;

				transition: .3s $easeInOutQuad;	
			    }
			}
		}
		.ci-navigation{
			
				transition: .3s $easeInOutQuad;
			&__tooltip{
				opacity: .6;
				transition: opacity .2s $easeInOutQuad;
			}
			&__option{
				position: relative;
			}
			&__option:hover{
				&:after{
					width: 29px;
    				border-radius: 30px
				}
				.ci-navigation{
					&__tooltip{
						opacity: 1;
					}
					&__submenu{
						top: 100%;
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&__submenu{
				position: absolute;
				top: 70%;
				left: 0%;
				min-width: 200px;
				background: white;
				padding: 16px 0;
				border-radius: 8px;
				box-shadow: 0 4px 8px rgba(black, .06);
				transition: .3s $easeInOutQuad;
				opacity: 0;
				visibility: hidden;
			}
			&__child{
				padding: 8px 16px;
				line-height: 120%;
			}
		}
	}

	@keyframes nav-tooltip {
		0% {
			transform: translate(16px, -50%);
		}
		60% {
			transform: translate(20px, -50%);
		}
		100% {
			transform: translate(16px, -50%);
		}
	}

	@keyframes nav-tooltip-inline {
		0% {
			transform: translate(-50%, -4px);
		}
		60% {
			transform: translate(-50%, 0);
		}
		100% {
			transform: translate(-50%, -4px);
		}
	}

}
</style>
