h<template>
	<div class="ld-recursospage">
		
		<div class="recursospage_top">
			<img class="ld-recursospage__shape" :src="`${publicPath}images/recursostop.png`">
			<ci-text type="body" class="recursospage_top__text">
				Integração entre médicos e paciente rápida  e fácil, com todos os recursos e segurança que a telemedicina oferece. <br><br>
				<strong>O WebDoctor está pronto para te ajudar</strong>
			</ci-text>
		</div>
		<div class="ld-recursospage__container">
			<div class="ld-recursospage__item">
				<figure class="ld-recursospage__item_icon">
					<img  :src="`${publicPath}images/shield.png`">
				</figure>

				<ci-text type="subhead">Segurança para médico e paciente</ci-text>
		
				<div class="top mt-5">
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Garantia de Segurança</ci-text>
							<ci-text type="small" class="smaller">Sigilo médico garantido por meio da tecnologia da informação com níveis de segurança e conversas criptografadas.</ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Praticidade ao enviar documentos</ci-text>
							<ci-text type="small" class="smaller">Requisição de exames, prescrição médica e atestados digitais com informações do paciente e do  profissional que emitiu.</ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Comunicação segura com o paciente</ci-text>
							<ci-text type="small" class="smaller">Materiais necessários enviados pelo médico através de links únicos por SMS e disponíveis no formato pdf ou imagem.</ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Identificação do médico</ci-text>
							<ci-text type="small" class="smaller">Uso de assinatura eletrônica e dados associados ao médico, como registro no CRM, evitando riscos de fraudes. </ci-text>
						</small>
					</div>
				</div>
				
			</div>
<!--  -->
			<div class="ld-recursospage__item" style="margin-bottom: 45px;">
				<figure class="ld-recursospage__item_icon">
					<img  :src="`${publicPath}images/online-medical-consultation.png`">
				</figure>

				<ci-text type="subhead">Praticidade e rapidez nas consultas</ci-text>
		
				<div class="top mt-5">
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Prontuário Express</ci-text>
							<ci-text type="small" class="smaller">Informações e dados clínicos necessários para a boa condução da consulta, preenchido em cada contato com o paciente.</ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Integração com operadora ou provedor</ci-text>
							<ci-text type="small" class="smaller">Aprovação direta da operadora ou plano de saúde, facilitando o atendimento e certificando os envolvidos.</ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Consultas em qualquer lugar do mundo</ci-text>
							<ci-text type="small" class="smaller">Médico, paciente e atendente podem se conectar de qualquer parte do mundo, basta apenas uma boa conexão de internet. </ci-text>
						</small>
					</div>
					<div class="topics">
						<figure>
							<img class="check" :src="`${publicPath}images/topics.png`">
						</figure>
						<small>
							<ci-text type="lead" class="lead">Disponível em versões Mobile e PC/Desktop</ci-text>
							<ci-text type="small" class="smaller">Os profissionais e pacientes podem acessar a plataforma usando o smartphone ou PC, facilitando o uso  e atendimentos. </ci-text>
						</small>
					</div>
				</div>
				
			</div>

			<!--  -->

			<div class="ld_content_cta mx-auto mb-5">
				<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank"><button class="ld_cta_pattern">Quero saber mais</button></a>
			</div>
			<!--  -->

			<div class="lasttopics">
				<img class="bg3" :src="`${publicPath}images/bg3.png`">
				<div class="row w-100">
					<div class="col-md-6">
						<h3>
							<strong>Fique atento às novidades</strong>
							Em breve novos recursos 
						</h3>
					</div>
					<div class="col-md-6">
						<div class="topicsList">
							<img class="check" :src="`${publicPath}images/topics.png`">
							<ci-text type="lead" class="lead">Integração total com loja online para receber pagamentos diretos.</ci-text>
						</div>

						<div class="topicsList">
							<img class="check" :src="`${publicPath}images/topics.png`">
							<ci-text type="lead" class="lead">Prontuário avançado e integrado por especialidades.</ci-text>
						</div>
						<div class="topicsList">
							<img class="check" :src="`${publicPath}images/topics.png`">
							<ci-text type="lead" class="lead">Anotações e consulta em apenas uma tela.</ci-text>
						</div>

					</div>
				</div>	
			</div>

			<h3 class="imprensa">Consulte a <a href="http://www.in.gov.br/en/web/dou/-/portaria-n-467-de-20-de-marco-de-2020-249312996" target="_blank">portaria do Ministério da Saúde</a> <br>
				e confirme que o WebDoctor atende a todas as medidas</h3>


			<img class="bottomshape" :src="`${publicPath}images/base-04.png`">
			
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
	},
	metaInfo: {
		title: 'Recursos'
    },
	props: {
		title: {
			type: String,
			default: () => ""
		},
		description: {
			type: String,
			default: () => ""
		},
		funcs: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}
			])
		},
		topics: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}
			])
		}
	},

	data: () => ({
		animated: false,
		visible: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
	.bg3{
		position: absolute;
		top: -14px;
		left: -86px;
		width: 53%;
	}
	.bottomshape{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 244%;
    height: 19%;
		-o-object-fit: cover;
		object-fit: cover;
		pointer-events: none;
		-o-object-position: center center;
		object-position: top center;
		z-index: -1;
	}
.bottomButtons{
	display: block;

	.ld_cta_yellow_pattern{
		padding: 15px 10px;
	}
	a{
		text-decoration: none!important;
		margin-left: 5px;
		margin-right: 5px;
	}
	.mobto{
		width: auto!important;
		margin: 0 auto 0!important;
		display: block;
		text-align: center;
		float: none!important;
	}
}
.imprensa{
	text-align: center;
	font-weight: bold;
	line-height: 140%;
	margin: 15vh 0;

	a{
		color: #1cbbc0;

		&:hover{
			color: #1cbbc0;
		}
	}
}
.ld-recursospage {
	position: relative;
	display: flex;
	align-items: top;
	position: relative;
	z-index: 30;
    flex-flow: column;
	min-height: 100vh;
	color: #65666d!important;
	background: #fbfbfb;

	.ld_cta_pattern {
		background: #1ebabe;
		text-align: center;
		border: none;
		border-radius: 35px;
		color: #fff;
		padding: 15px 20px;
		font-family: 'Poppins';
		font-size: 15px;
		font-weight: 600;
		cursor: pointer;
	}
	.ci-text--subhead{
		font-size: 24px;
		color: #65666d!important;
	}
	.ci-text--small {
	    font-size: 13px;
	    line-height: 20px;
	    font-weight: normal;
	    color: #65666d;
	}
	.no-card{
		border:none;
		padding: 0;
	}
	.tablist{
		border-top: solid 1px #fff7d4;
	}
	.b-grey{ 
		border-bottom: solid 1px #ececec;
	}
	.headAc{
		border: none;
		color: #65666d;
		background: none;
		padding: 22px 0;
		cursor: pointer;
	}
	.card-header{
		border: none;
		background: none;
		border-bottom: solid 1px #ececec;
		color: #65666d;
	}
	.lead{
		letter-spacing: 0.5px;
		font-weight: 600;
		color: #65666d;
		font-size: 19px;
	}
	.recursospage_top{
		position: relative;
		width: 100vw!important;
		background: #fff;
		overflow: hidden;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 43px 0;
		box-shadow: 1px 8px 8px 0px #f3f3f3;

		&__text{
			max-width: 577px;
			padding: 0 15px; 
			z-index: 999;
			color: #65666d;
			font-size: 18px;

			strong{
				font-size: 22px;
				margin-top: -13px;
				float: left;
				text-align: center;
				width: 100%;
			}
		}
	}
	&__page{
	    position: absolute;
	    bottom: -32%;
	    left: 0;
	    width: 83%;
	    -o-object-position: center center;
	    object-position: center center;
	    -o-object-fit: cover;
	    object-fit: cover;
	    right: 0;
	    margin: auto;
	}
	&__shape{
		position: absolute;
		left: 0;
		top: 0;
	    width: 100%;
	    height: 100%;
		object-fit: cover;
		pointer-events: none;
		object-position: center center;
	}
    		
	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: top;
    	flex-flow: column;
    	margin-top: 13vh;
	}
	&__item{
		border: solid 1px #fff;
		border-radius: 10px;
		background: rgb(255, 255, 255);
		opacity: 1;
		z-index: 99;
		position: relative;
		align-self: top;
    	padding: 10vh 3vw 6vh;
    	text-align: center;
    	margin-bottom: 9vh;
    	@include grid-column(6);
    	box-shadow: 0px 0px 13px 0px #f2f2f2;

    	.description{
    		font-size: 15px;
    		margin: 10px 0 25px;
    	}

		&_icon{
			position: absolute;
		    top: -34px;
		    margin-left: auto;
		    margin-right: auto;
		    left: 0;
		    right: 0;
		    width: 54px;
		    height: 54px;
		    border-radius: 100%;
		    text-align: center;
		    line-height: 50px;
		    background: #73b3ba;

			img{
				width: 48%;
				max-width: 29px;
			}
	
		}
		.topics{
			text-align: left;
			display: flex;
			flex-flow: row;
			@include grid-column(6);
			color: #666666;
			margin-bottom: 26px;

			.check{
				width: 22px;
				margin-right: 10px;
				margin-top: 9px;
			}
			.smaller{
				width: 100%;
			}
		}
	}
	.lasttopics{
		width: 100%;
		background: #fff;
		border-radius: 10px;
		    position: relative;
    overflow: hidden;
		box-shadow: 1px 8px 8px 0px #f3f3f3;
		@include grid-column(6);
		display: flex;
		align-items: center;
		padding: 40px;

		h3{
		    font-size: 18px;
		    line-height: 177%;
		    font-weight: 400;
		    /* text-align: center; */
		    margin: 0 auto;
		    display: flex;
		    flex-flow: column;
		    width: fit-content;
		    margin-top: 10px;
			strong{
			    font-size: 22px;
			}
		}

		.topicsList{
			display: flex;
			flex-flow: row;
			align-items: start;
			margin-bottom: 10px;
		}
		.check{
			width: 16px;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-flow: row;
			align-self: start;
			margin-top: 4px;
		}
		.lead{
			font-size: 13px;
			float: left;
			font-weight: 600;
			margin-left: 13px;
		}
	}
	@include grid-media($g-tablet-up){
		position: relative;
		display: flex;
		flex-flow: row wrap;
		align-items: top;
		position: relative;
		
		.top{
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
			display: block;
		}
		.bottomButtons{
			display: inline-flex;

			.ld_cta_yellow_pattern{
				padding: 15px 10px;
				margin: 0 10px;
			}
		}
		.lasttopics{
			@include grid-column(12);	
		}
		&__item{

    		@include grid-column(12);

    		.topics{

				@include grid-column(12);
				-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
		          page-break-inside: avoid; /* Firefox */
		               break-inside: avoid; /* IE 10+ */
    		}
			.description{
				width: 70%;
				margin: 20px auto 33px;
				color: #666666;
				font-size: 16px;
			}

			&_icon{
				top: -31px;
				width: 64px;
				height: 64px;
				line-height: 66px;
			}
		}
	}
	@include grid-media($g-desktop-up){
		

		&__item{
    		@include grid-column(12);
    		margin: 0 auto 12vh;

    		.smaller{
    			width: 98%;
    		}

    		&:last-child{
    		}
		}
	}
}
@media(max-width: 600px){
	.topicsList{
		margin-top: 30px;
	}
}
</style>
