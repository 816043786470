<template>
	<div class="ld-sobrepage">
		<ld-sobrepage
			ref="sobrepage"
			id="sobrepage"
			class="ld-details__page"
		/>
	</div>
</template>

<script>
import LDSobrePage from '@/components/organisms/LDSobrePage'

export default {
	components: {
		'ld-sobrepage': LDSobrePage
	},
	props: {
		sobrepage: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: []
	}),
		
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.ld-sobrepage{
	overflow: hidden;
	background: #fafafa;
	margin-top: 32px;
}

@include grid-media($g-desktop-up) {
	.ld-sobrepage{
		margin-top: 47px;
	}
}
</style>
