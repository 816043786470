<template>
	<ld-naoencontrado
		id="naoencontrado"
	/>
</template>

<script>
import LDNaoEncontrado from '@/components/templates/LDNaoEncontrado'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ld-naoencontrado': LDNaoEncontrado
	},

	props: {

		
	},
	data: () => ({
		selected: '/naoencontrado',
		value: '/naoencontrado'
		}),
	computed: {
		...mapGetters({
		})
	},

	methods: {
	}
}
</script>
