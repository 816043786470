<template>
		<div class="ld-semsair">
				<img class="ld-semsair__shape" :src="`${publicPath}images/shape-hero-02.png`">
			<div class="ld-semsair__container">
				<div class="ld-semsair__mock">				
					<img class="mock02" :src="`${publicPath}images/mock-02.png`">
				</div>
				
				<div class="ld-semsair__content">
		
					<ci-text class="ld-semsair__title" type="title">
						<small style="font-size: 62%">Pacientes</small><br>
						O apoio que você precisa <br> <span>através do celular ou tablet</span>
					</ci-text>
					<ci-text class="ld-semsair__text ld-semsair__animate" type="lead">
						Faça teleconsultas e disponibilize solicitação de exames, atestado e prescrição médica. Tudo feito com com autorização do plano de saúde.
					</ci-text>

					<div class="ld_content_cta mr-auto thisonectas">
						<a href="http://materiais.citrus.cx/midas-webdoctor" target="_blank"><button class="ld_cta_pattern">Sou cliente Midas</button></a>
						<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank"><button class="ld_cta_pattern_sec">Quero testar o Webdoctor</button></a>
					</div>
				</div>

			</div>
		</div>
	</template>

	<script>
	import CIText from '@/components/atoms/CIText'
	import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

	export default {
		components: {
			'ci-text': CIText
		},
		props: {
			mock: {
				type: String,
				default: undefined
			},
			thumbnail: {
				type: String,
				default: undefined
			},
			title: {
				type: String,
				default: ''
			},
			content: {
				type: String,
				default: 'Busca uma forma prática e eficiente de otimizar seu atendimento? Quer fazer tudo isso e ainda vender muito mais? '
			},
			theme: {
				type: String,
				default: 'accent'
			},
			initialized: {
				type: Boolean,
				default: false
			}
		},

		data: () => ({
			animated: false
		}),

		mounted() {
			if (this.initialized)
				this.init()
		},

		watch: {
			initialized(value) {
				if (value && !this.animated)
					this.init()
			}
		},

		methods: {
			init() {
				this.animated = true

				TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
					x: "-=80",
					opacity: 0,
					ease: Power2.easeOut
				}, .4)
			},
		}
	}
	</script>

	<style lang="scss">
	.ld-semsair{
		position: relative;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 30;
	    padding: 6vh 0;
		.thisonectas{
			text-align: left;

			button{
				width: 100%;
				max-width: 318px;
				outline: none!important
			}
			.ld_cta_pattern_sec{
				margin-left: 0!important;
				margin-top: 10px;
			}
		}
		&__shape{
			display: none;
		}
		&__container{
			width: 100%;
			
			display: flex;
	    	align-items: center;
	    	flex-flow: wrap;
		}
		&__mock{
			
			@include grid-column(6);

			img{
				width: 100%
			}
		}
		&__content{
			@include grid-column(6);
		}
		&__title{
			color: #666666;
			float: left;
			font-size: 24px;
			line-height: 120%;
			margin-top: 18px;
			font-weight: 600;

			span{
				font-size: 30px;
				font-weight: bold;
				color: #1ebabe;
			}
		}
		&__text{
			color: #64666b;
		    font-size: 16px;
		    line-height: 150%;
		    float: left;
		    font-weight: 500;
		    letter-spacing: 0.5px;
		    margin: 17px 1px;
		}
		@include grid-media($g-tablet-up){

			&__mock{
			
				@include grid-column(6);

				img{
					width: 100%
				}
			}
			&__shape{
				position: absolute;
				right: -19px;
				top: 0;
				width: 295px;
				z-index: -3!important;
				display: block!important;
			}
			&__content{
				@include grid-column(6);
			}
			&__title{
				color: #666666;
				float: left;
				font-size: 26px;
				line-height: 120%;
				
				font-weight: 600;

				span{
					font-size: 30px;
					font-weight: bold;
					color: #1ebabe;
				}
			}
			&__text{
				color: #64666b;
				font-size: 17px;
				line-height: 150%;
				float: left;
				font-weight: 500;
				letter-spacing: 0.5px;
			}

		}
		@include grid-media($g-desktop-up){
			padding: 10vh 0;
			position: relative;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			position: relative;
			&__shape{
				position: absolute;
				right: 0;
				top: 175px;
				z-index: -3!important;
			}
			&__mock{

				float: left;
				width: 52%;	
				margin: 0!important;

				img{
					width: 100%;
				}
			}
			&__content{
				width: 28%;
				margin-left: 110px;
				float: left;
			}
			&__container{
	    		display: flex;
	    		align-items: center;
			}
			&__title{

				font-size: 32px;
				line-height: 120%;
				
				font-weight: 600;

				span{
					font-size: 37px;
					font-weight: bold;
					color: #1ebabe;
				}
			}
			&__text{
				margin: 18px 0 22px!important;
				font-size: 17px;
			}
		}
	}
	@media(max-width: 768px){
		.ld-semsair{

			@include grid-container;
		}
		.ld-semsair__container{
		@include outer-container;
		}
	}
	@media(max-width: 600px){
		.ld-semsair .thisonectas button{
			max-width: 100%!important;
			margin-bottom: 0;
		}
	}
	</style>
