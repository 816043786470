<template>
	<div class="ld-beneficios"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>	
		<div class="ld-beneficios__container">
			<div class="ld-beneficios__content">
				<ci-text class="ld-beneficios__title" style="line-height: 135%;">Os serviços que você precisa, <br>tudo online</ci-text>
				<div class="ld-beneficios__items mt-5">

					<div class="ld-beneficios__item">
						<figure class="ld-benefit__circle">
							<img class="ld-benefit__icon" :src="`${publicPath}images/team.png`">
						</figure>
						<ci-text class="ld-benefit__comment">
							Os melhores profissionais de saúde em um só lugar
						</ci-text>
					</div>
					<div class="ld-beneficios__item">
						<figure class="ld-benefit__circle">
							<img class="ld-benefit__icon" :src="`${publicPath}images/help.png`">
						</figure>
						<ci-text class="ld-benefit__comment">
							 Atendimento individual e humanizado
						</ci-text>
					</div>
					<div class="ld-beneficios__item">
						<figure class="ld-benefit__circle">
							<img class="ld-benefit__icon" :src="`${publicPath}images/synchronize.png`">
						</figure>
						<ci-text class="ld-benefit__comment">
							Prontuário único, seguro e integrado
						</ci-text>
					</div>
					<div class="ld-beneficios__item">
						<figure class="ld-benefit__circle">
							<img class="ld-benefit__icon" :src="`${publicPath}images/calendar.png`">
						</figure>
						<ci-text class="ld-benefit__comment">
							Autorizador de consultas pelo plano de saúde
						</ci-text>
					</div>
				</div>
				
				<div class="bottom-info">
					<div class="ld_content_cta mx-auto">
						<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank"><button class="ld_cta_pattern">Quero saber mais</button></a>
					</div>
				</div>		
			</div>
		</div>
		<h3 class="imprensa">Consulte a <a href="http://www.in.gov.br/en/web/dou/-/portaria-n-467-de-20-de-marco-de-2020-249312996" target="_blank">portaria do Ministério da Saúde</a> <br>
				e confirme que o WebDoctor atende a todas as medidas</h3>
	</div>
</template>

<script>

import CIText from '@/components/atoms/CIText'
import LDBenefit from '@/components/molecules/LDBenefit'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ld-benefit': LDBenefit
	},
	props: {
		
		description: {
			type: String,
			default: 'Reúna seus canais de atendimento em um único lugar'
		},
		icon: {
			type: String, 
			default: 'images/about.svg'
		},
		content: {
			type: String,
			default: 'Através de uma completa suíte de comunicação, o Lemon Desk permite o atendimento aos clientes, por meio de qualquer canal de comunicação, em uma única ferramenta. '
		},
		
		initialized: {
			type: Boolean,
			default: true
		},
		benefits: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}
			])
		},
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">

.ld-beneficios{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	z-index: 30;
	
	.imprensa{
		text-align: center;
		font-weight: bold;
		line-height: 140%;
		margin: 0 auto 40px;
		color: #5b5c61;
		
		a{
			color: #1cbbc0;

			&:hover{
				color: #1cbbc0;
			}
		}
	}
	.automr{
		p{
			font-family: 'Avenir';
			color: #2B2D42;
			line-height: 17px;
			width: 225px;
			margin: 14px auto!important;
		}
		small{
			font-size: 80%;
			font-weight: 400;
			margin-top: 8px;
			float: left;
		}
	}	
	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: center;
	}
	&__circle{

	}
	&__content{
		/*@include grid-column(6);*/
		width: 100%;
		margin: 16px;
		   padding: 45px 16px;
		text-align: center;
		background: #fff;
	    box-shadow: 0px 0px 12px 3px #f4f4f4;
	    border-radius: 8px;
	    margin-bottom: 4vh;
	}
	&__items{
		text-align: center;
		margin: 30px 0;
		align-items: center;
	    display: inline-flex;
	    flex-wrap: wrap;
	    justify-items: center;
	}
	&__item{
		width: calc(100% - 32px);
	    float: left;
	    margin-left: 16px;
	    font-size: 14px;
	    margin-bottom: 35px;
	    color: #555353;
	}
	&__title{
		margin: 0 auto;
		font-size: 28px;
		line-height: 33px;		
		color: #33c0c5;
		font-weight: 700;
	}
	.bottom-info{
		display: inline-flex;
   		flex-flow: column;
	}
	.start__txt {
	    font-size: 26px;
	    font-weight: 600;
	    text-align: center;
	    line-height: 120%;
	    margin-left: 0;
    	margin-bottom: 20px;
	}
	@include grid-media($g-tablet-up) {
		&__title{
			
			font-size: 36px;
			
		}	
		&__item{
			width: calc(25% - 18px);
			float: left;
			margin-left: 16px;
			font-size: 14px;
			margin-bottom: 25px;
			color: #555353;
		}
		&__content{
			margin-bottom: 12vh;
		}
		.bottom-info{
			display: flex;
			align-items: center;
		    justify-content: space-around;
		    flex-flow: row;
		}
		.start__txt{
			font-size: 28px;
			font-weight: 600;
			text-align: left;
			line-height: 120%;
			margin-left: -1vw;
			margin-bottom: 20px;
		}
	}
	.ld-benefit__circle{
		img{
			width: 41%;
		}
	}
	@include grid-media($g-desktop-up) {
		&__title{
			margin: 0 auto;
			font-size: 40px;
		}
		.start__txt{
			font-size: 34px;
		}

		&__content{
			width: 90%;
	    	max-width: 1024px;
			margin: 0 auto;
			text-align: center;
			background: #fff;
		    box-shadow: 0px 0px 12px 3px #f4f4f4;
		    border-radius: 8px;
		    padding: 4vw;
		    margin-bottom: 12vh;
		}
	}

}



</style>
