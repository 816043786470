<template>
	<div class="ld-hero">
		<div class="ld-hero__container">			
			<img class="ld-hero__shape_1 ld-hero__animate" :src="`${publicPath}images/shape-hero-1.svg`">
			<img class="ld-hero__shape" :src="`${publicPath}images/shape-hero-01.png`">

			
			<img class="ld-hero__screen ld-hero__animate" :src="`${publicPath}images/Screen.png`" :alt="title">

			<div class="ld-hero__content">
				<ci-text class="ld-hero__title ld-hero__animate" type="title">
					<span>Consultas rápidas e práticas no conforto e segurança de suas casas.</span>
				</ci-text>

				<ci-text class="ld-hero__text ld-hero__animate" type="lead" v-html="content">
				</ci-text>

				<div class="ld_content_cta mr-auto">
					<button class="bla ld_cta_pattern">Sou paciente</button>
					<a href="https://materiais.citrus.cx/webdoctor-cadastro-de-interessados" target="_blank"><button class="ld_cta_pattern_sec">Sou médico</button></a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'
import $ from "jquery"

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		thumbnails: {
			type: String,
			default: 'images/Screen.png'
		},
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: 'O Webdoctor é a ferramenta que médicos e operadoras de saúde precisam para que pacientes sejam atendidos de forma segura e eficiente. Com recursos de telemedicina, garante sigilo e proteção dos dados e atende todas as medidas da Portaria nº 467 do Ministério da Saúde sobre o COVID-19.'
		},
		theme: {
			type: String,
			default: 'accent'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()

		$(".bla").click(function(){
			var offsets = $('#semsair').offset();
			var top = offsets.top;
			$(".ci-app__body").animate({scrollTop: top - 50}, '500', 'swing');
		})
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ld-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .2)
		},
	}
}
</script>

<style lang="scss">
.ld-hero{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;
	height: auto;
	min-height: 100vh;
	background: #fafafa;	
    padding-top: 0;
	@include grid-container;

	
	.mr-auto{
		
		p{
			font-family: 'Avenir';
			color: #2B2D42;
			line-height: 17px;
			width: 225px;
			margin: 14px auto!important;
		}
		small{
			font-size: 80%;
			font-weight: 400;
			margin-top: 8px;
			float: left;
		}
	}
	&__container{
		width: 100%;
		@include outer-container;

		.ld_content_cta{
			float:none!important;
		}
	}
	&__shape{
		position: absolute;
		right: -152%;
		top: 0;
		z-index: -1;
		pointer-events: none;
		/* transform: rotate(44deg); */
		height: 366px;
	}
	&__shape_1{
		position: absolute;
		width: 27%;
		left: -13%;
		top: 20%;
		z-index: -1;
		pointer-events: none;
	}
	&__shape_2{
		position: absolute;
		width: 20%;
		left: 25%;
		top: -35%;
		z-index: -1;
		pointer-events: none;
	}
	&__shape_3{
		position: absolute;
		width: 37%;
		right: -26%;
		top: -18vh;
		z-index: -1;
		pointer-events: none;
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
		margin: 16px 0;
		color: #65666d;
		font-size: 27px;
		text-align: center;

		span{
			color: #12b7bd;
		}
	}
	&__text{
		color: #64666b;
	    font-size: 15px;
	    margin-bottom: 2px;
	    line-height: 150%;
	    font-weight: 500;
	    margin-bottom: 21px;
	    text-align: center;		
	}
	&__screen{
		width: 100%;
		max-width: 640px;
		position: relative;
		pointer-events: none;
		margin: 17% auto 0;
		display: block;
	}

	@include grid-media($g-tablet-up){

    	padding-top: 0;
    	
		&__content{
			width: 65%;
			margin: 3vh auto 5vh;
			float: none;
			font-weight: 500;
			text-align: center;
		}
		&__shape {
			top: 0;
			position: absolute;
			z-index: -1;
			pointer-events: none;
			left: auto;
			right: -38%;

		}
		&__title{
		    margin: 0 0 22px;
			font-size: 34px;
			line-height: 43px;
			letter-spacing: 0px;
			padding-right: 20px;
			text-align: left;
		}
		&__shape_1 {
			position: absolute;
			width: 43%;
			left: -18%;
			top: 9%;
			z-index: -1;
			pointer-events: none;
		}
		&__text{
			color: $c-darkgray;
			font-size: 16px;
			margin-bottom: 2px;
			line-height: 150%;
			font-weight: 500;
			margin-bottom: 23px;
			text-align: left;
		}
	}


	@include grid-media($g-desktop-up) {
		height: 91vh;	
    	padding-top: 14vh;

		&__content{
			@include grid-column(5);
			
			text-align: left;
		}
		&__shape{
			width: 60%;
			right: 0;
			left: auto;
			top: -22px;
		}
		&__shape_1{
			position: absolute;
			width: 31%;
			left: -24%;
			top: 2%;
			z-index: -1;
			pointer-events: none;
		}
		&__screen{
			max-width: 1024px;
			right: 3.3%;
			top: 20%;
			width: 52%;
			margin-bottom: 0;
			margin-top: 0;
			position: absolute;
			pointer-events: none;
		}
		.ld_content_cta{
			float: left!important;
		}
	}	

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
