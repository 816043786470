<template>
	<div class="ld-naoencontrado">
		<ld-naoencontrado
			ref="naoencontrado"
			id="naoencontrado"
			class="ld-details__page"
		/>
	</div>
</template>

<script>
import LDNaoEncontrado from '@/components/organisms/LDNaoEncontrado'

export default {
	components: {
		'ld-naoencontrado': LDNaoEncontrado
	},
	props: {
		naoencontrado: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: []
	}),
		
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.ld-naoencontrado{
	overflow: hidden;
	background: #ffffff;
	margin-top: 32px;
}

@include grid-media($g-desktop-up) {
	.ld-naoencontrado{
		margin-top: 47px;
	}
}
</style>
