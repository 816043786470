<template>
	<div class="ci-brand">
		<router-link to="/">

			<img class="ci-brand__image"
				:src="`${publicPath}images/web.doctor.png`" alt="WebDoctor" title="WebDoctor"
			>

		</router-link>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	props: {
		type: {
			type: String,
			default: 'citrus'
		}
	},
	data: () => ({
		// topScroll: 0,
		// brand: 'images/lemon-desk.png',
		// branddark: 'images/lemon-desk-dark.png'

	}),
	
}
</script>

<style lang="scss">
.ci-brand{
	a{
		flex: auto;
	}
	&__image{
		width: 100%;
		max-width: 210px;
		height: auto;
		/*position: absolute;*/
		margin-top: 0px;
	}
}
</style>
