<template>
	<ld-sobrepage
		id="sobrepage"
	/>
</template>

<script>
import LDSobrePage from '@/components/templates/LDSobrePage'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ld-sobrepage': LDSobrePage
	},

	props: {

		
	},
	data: () => ({
		selected: '/sobrepage',
		value: '/sobrepage'
		}),
	computed: {
		...mapGetters({
		})
	},

	methods: {
	}
}
</script>
