<template>
	<div class="ld-sobre"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"

	>	
		<div class="ld-sobre__container">
			<div class="ld-sobre__content">
				<ci-text class="ld-sobre__title" v-html="title"></ci-text>
				<ci-text class="ld-sobre__text" v-html="content"></ci-text>
				
			</div>
		</div>
	</div>
</template>

<script>
import CICircle from '@/components/atoms/CICircle'
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-circle': CICircle
	},
	props: {
		
		title: {
			type: String,
			default: 'Conheça o WebDoctor'
		},
		content: {
			type: String,
			default: 'O Webdoctor é a ferramenta que facilita  integração de profissionais de saúde e pacientes. Garantindo segurança com recursos de proteção como biometria e links autorizados. Está disponível 100% em plataforma móveis, como celular e tablet ou pode ser usado no computador. Consultas rápidas e eficientes sem sair de casa.'
		},
		
		initialized: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ld-sobre{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	overflow: hidden;
	z-index: 0;
	margin-bottom: 8vh;

	&__container{
		width: 100%;
		@include outer-container;
		display: flex;
    	align-items: center;
	}
	
	&__content{
		@include grid-column(6);
	}
	&__title{
		color: #61ccd0;
		font-size: 36px;
		line-height: 120%;
		font-weight: bold;
		text-align: center;
		margin: 0 auto 20px;
	}
	&__text{
		color: #7d7878;
		font-size: 17px;
		line-height: 165%;
		float: none;
		margin: 0 auto 30px;
		max-width: 1020px;
		text-align: center;
		font-weight: 500;
	}
	&__shape{
		display: none;
	}
	&__items{
		display: flex;
		flex-flow: column;
	}	
	&__item{
		width: 100%;
		float: left;
		/*margin-left: 16px;*/
		text-align: center;
		color: #292929;

		figure{
			padding: 30px;
			border-radius: 4px;
			margin: 0 0 0 0!important;
			cursor: default!important;
			
			img{
				margin: 0 0 14px 0!important;
			}
			span{
				margin-bottom: 0!important;
			}
		}
		
	}

	@include grid-media($g-tablet-up) {
		&__title{
			color: #61ccd0;
			font-size: 41px;
			line-height: 120%;
			font-weight: bold;
			text-align: center;
			margin: 0 auto 20px;
		}
		&__text{
			color: #7d7878;
			font-size: 20px;
			line-height: 165%;
			float: none;
			margin: 0 auto 30px;
			max-width: 1020px;
			text-align: center;
			font-weight: 500;
		}
		
	}

	@include grid-media($g-desktop-up) {
		
	}
}
</style>
