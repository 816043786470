<template>
    <div class="project34" v-if="show">
        <div class="modal-project34">
            <div class="modal-project34-body">
                <a href="https://softex.br/iamcti/" target="_blank">
                    <img class="img-project34" :src="`${publicPath}images/modal-webdoctor.png`" />
                </a>
                <div class="btn-close" v-on:click="show = false">
                    <img class="img-close" :src="`${publicPath}images/modal-webdoctor-close.png`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true
        }
    }
}
</script>

<style lang="scss">
    .project34 {
        position: fixed;
        z-index: 50;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);

        .modal-project34 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .modal-project34-body {
                position: relative;
                user-select: none;

                .btn-close {
                    position: absolute;
                    cursor: pointer;
                    top: 5%;
                    right: 10%;
                }

                .img-project34 {
                    width: 600px;
                    cursor: pointer;
                }

                @media screen and (max-width: 730px) {
                    .img-project34 {
                        width: 90vw;
                        cursor: pointer;
                    }

                    .btn-close {
                        top: 5%;
                        right: 8%;
                    }

                    .img-close {
                        width: 80%;
                    }
                }
            }
        }
    }
</style>